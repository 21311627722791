'use client';

import { useDebounceFn } from '@reactuses/core';
import { useCallback, useEffect, useState } from 'react';
import { swrFetcher } from '../utils/swr-fetcher';

export interface UsePaginatedResourceProps<T> {
  /**
   * URL of the endpoint
   */
  url: string;

  /**
   * Size of the batch
   */
  limit?: number;

  /**
   * Size of the offset for the pagination
   */
  offset?: number;

  /**
   * Concatenate response. Either fetches the batch from 1-5 and the next page from 6 -10, or it fetches 1-5 on the
   * first page and 1-10 on the second.
   *
   * Concatenation is useful when you have some sort of infinite scrolling
   */
  concat?: boolean;

  params?: object;
  initialLoad?: boolean;
  initialData?: T | null;
}

export const usePaginatedResource = <T>({
  url,
  concat = false,
  limit: initialLimit,
  offset: initialOffset,
  initialData,
  initialLoad = true,
  params,
}: UsePaginatedResourceProps<T>) => {
  const [allowFetchData, setAllowFetchData] = useState(initialLoad);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(initialLimit ?? 5);
  const [offset, setOffset] = useState(initialOffset ?? 0);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | null>(initialData ?? null);

  const fetch = useDebounceFn((p: object = {}) => {
    setIsLoading(true);
    swrFetcher<T>({
      url: allowFetchData ? url : null,
      params: {
        limit: concat ? limit : limit,
        offset: concat ? 0 : offset,
        concat,
        ...params,
        ...p,
      },
    })
      .then((response) => {
        setError(null);
        setData(response ?? null);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
    setAllowFetchData(true);
  }, 400);

  const next = useCallback(() => {
    setAllowFetchData(true);
    setOffset(offset + limit);
    fetch.run({ offset: 0, limit: offset + limit });
  }, [offset, limit, fetch.run]);

  const reset = useCallback(() => {
    setAllowFetchData(true);
    setLimit(initialLimit ?? 10);
    // setOffset(initialOffset ?? 0);
  }, []);

  return {
    limit,
    offset,
    fetch: fetch.run,
    data,
    next,
    error,
    isLoading,
    reset,
  };
};
