import { Typo } from '@dreipol/pusch-components';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { DatoColor } from '../../../types/dato';
import classes from './filter-indicator.module.scss';

export type FilterIndicatorProps = {
  color: DatoColor;
  label: ReactNode;

  className?: string;
};
export const FilterIndicator = ({
  color,
  label,
  className,
}: FilterIndicatorProps) => {
  return (
    <div className={clsx(classes.root, classes[color], className)}>
      <div className={classes.indicator} />
      <Typo className={classes.label} variant={'paragraph2'} as={'span'}>
        {label}
      </Typo>
    </div>
  );
};
