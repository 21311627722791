import {
  AccordionItem,
  Button,
  Checkbox,
  ChevronDownIcon,
  CloseIcon,
  FilterIcon,
  IconButton,
  MenuList,
  MenuListItem,
  Popup,
  Typo,
} from '@dreipol/pusch-components';
import { Media } from '@dreipol/t3-react-media';
import { useSelectionList } from '@dreipol/t3-react-utils';
import { intersection } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIdList } from '../../hooks/use-id-list';
import { FilterGroup, FilterGroupItem } from '../../services/get-filters';
import { useScrollLock } from '@dreipol/t3-react-utils';
import classes from './dynamic-filter-renderer.module.scss';
import React from 'react';

export type DynamicFilterRendererProps = {
  filterType: 'map' | 'page';
  popUpPosition: 'left' | 'center' | 'right' | undefined;
  filters: FilterGroup[];
  activeFilters: string[];
  onChange?: (activeFilter: FilterGroupItem[]) => void;
  onClear?: () => void;
};

export const DynamicFilterRenderer = ({
  filterType,
  popUpPosition = 'right',
  filters,
  onChange,
  onClear,
  activeFilters,
}: DynamicFilterRendererProps) => {
  const openPopups = useSelectionList<string>();
  const [selectedPopUp, setSelectedPopUp] = useState('rubric');
  const internalFilters = useSelectionList<FilterGroupItem>([]);
  const { t } = useTranslation();
  const scrollLock = useScrollLock();

  const filterList = useIdList<FilterGroupItem, string>(
    (filters ?? []).reduce((items, item) => {
      return items.concat(item.items);
    }, [] as FilterGroupItem[]),
    [filters],
  );

  const onApply = useCallback(() => {
    onChange?.(internalFilters.list);
    openPopups.clear();
  }, [internalFilters, onChange]);

  const activeItemsCount = useCallback(
    (groupItems: FilterGroupItem[]) =>
      intersection(
        groupItems.map(({ id }) => id),
        activeFilters,
      ).length,
    [activeFilters],
  );

  useEffect(() => {
    internalFilters.setList(activeFilters.map((id) => filterList.get(id)!));
  }, [activeFilters, filterList]);

  /**
   * Handle scroll lock
   */
  useEffect(() => {
    const header = document.getElementById('header');
    const popUpOpen = openPopups.has('popup');

    //hide header on page
    if (window && header && filterType === 'page') {
      popUpOpen
        ? (header.style.display = 'none')
        : (header.style.display = 'block');
    }

    scrollLock(popUpOpen);
  }, [openPopups, scrollLock]);

  const renderDesktopFilters = useMemo(
    () => (
      <>
        {(filters ?? []).map(
          (group) =>
            ((filterType === 'page' && group.items.length > 1) ||
              (filterType === 'map' &&
                group.id !== 'format' &&
                group.items.length > 1)) && (
              <Popup
                key={group.id}
                position={popUpPosition}
                open={openPopups.has(group.id)}
                onClose={() => openPopups.remove(group.id)}
                anchor={
                  <Button
                    fullWidth
                    onClick={() => openPopups.toggleItem(group.id)}
                    variant={
                      activeItemsCount(group.items) > 0
                        ? 'contained'
                        : 'outlined'
                    }
                    endAdornment={<ChevronDownIcon />}
                  >
                    {`${t(`filter.${group.group}`)} (${activeItemsCount(
                      group.items,
                    )})`}
                  </Button>
                }
              >
                <MenuList
                  footer={
                    <MenuListItem disablePadding disableHover>
                      <Button fullWidth onClick={onApply}>
                        {t('filter.apply_label')}
                      </Button>
                    </MenuListItem>
                  }
                >
                  {group.items.map((item) => (
                    <MenuListItem key={`${group.id}-${item.id}`}>
                      <Checkbox
                        label={item.term}
                        isChecked={internalFilters.has(item)}
                        onChange={() => internalFilters.toggleItem(item)}
                      />
                    </MenuListItem>
                  ))}
                </MenuList>
              </Popup>
            ),
        )}
      </>
    ),
    [openPopups, activeItemsCount, internalFilters, filters],
  );

  const renderMediumFilters = useMemo(
    () => (
      <>
        <Popup
          position={popUpPosition}
          open={openPopups.has('popup')}
          anchor={
            <Button
              fullWidth
              color={'secondary'}
              endAdornment={<FilterIcon />}
              onClick={() => openPopups.toggleItem('popup')}
              variant={activeFilters.length > 0 ? 'contained' : 'outlined'}
            >
              {activeFilters.length > 0
                ? t('filter.edit_label')
                : t('filter.label')}
            </Button>
          }
        >
          <MenuList
            footer={
              <MenuListItem disablePadding disableHover>
                <Button onClick={onApply} fullWidth>
                  {t('filter.apply_label')}
                </Button>
              </MenuListItem>
            }
          >
            {(filters ?? []).map((group) => (
              <React.Fragment key={group.id}>
                {group.items.map((item) => (
                  <MenuListItem key={`${group.id}-${item.id}`}>
                    <Checkbox
                      label={item.term}
                      isChecked={internalFilters.has(item)}
                      onChange={() => internalFilters.toggleItem(item)}
                    />
                  </MenuListItem>
                ))}
              </React.Fragment>
            ))}
          </MenuList>
        </Popup>
      </>
    ),
    [openPopups, activeFilters, internalFilters, filters],
  );

  const renderSmallFilters = useMemo(
    () => (
      <>
        <Popup
          className={classes.root}
          fullscreen
          header={
            <>
              <Typo
                className={classes.popUpHeaderTitle}
                variant={'h2'}
                as={'span'}
              >
                {t('filter.label')}
              </Typo>
              <IconButton
                variant={'plain'}
                onClick={() => openPopups.remove('popup')}
              >
                <CloseIcon size="large" />
              </IconButton>
            </>
          }
          position={popUpPosition}
          open={openPopups.has('popup')}
          anchor={
            filterType === 'page' ? (
              <IconButton
                color={'secondary'}
                onClick={() => openPopups.toggleItem('popup')}
                variant={activeFilters.length > 0 ? 'contained' : 'outlined'}
              >
                <FilterIcon />
              </IconButton>
            ) : (
              <Button
                fullWidth
                color={'secondary'}
                endAdornment={<FilterIcon />}
                onClick={() => openPopups.toggleItem('popup')}
                variant={activeFilters.length > 0 ? 'contained' : 'outlined'}
              >
                {activeFilters.length > 0
                  ? t('filter.edit_label')
                  : t('filter.label')}
              </Button>
            )
          }
        >
          <MenuList
            footer={
              <MenuListItem
                className={classes.buttonContainer}
                direction={'column'}
                disablePadding
                disableHover
              >
                <Button
                  fullWidth
                  className={classes.clearButton}
                  variant={'link'}
                  typoVariant={'textlink'}
                  onClick={onClear}
                >
                  {t('filter.reset_all')}
                </Button>
                <Button onClick={onApply} fullWidth>
                  {t('filter.apply_label')}
                </Button>
              </MenuListItem>
            }
          >
            {(filters ?? []).map(
              (group) =>
                ((filterType === 'page' && group.items.length > 1) ||
                  (filterType === 'map' &&
                    group.id !== 'format' &&
                    group.items.length > 1)) && (
                  <AccordionItem
                    key={group.id}
                    open={selectedPopUp === group.id}
                    onOpen={() => {
                      setSelectedPopUp(group.id);
                    }}
                    header={
                      <Typo variant={'h4'}>
                        {t(`filter.${group.group}`)} (
                        {activeItemsCount(group.items)})
                      </Typo>
                    }
                  >
                    {group.items.map((item) => (
                      <MenuListItem
                        disablePadding
                        key={`${group.id}-${item.id}`}
                      >
                        <Checkbox
                          label={item.term}
                          isChecked={internalFilters.has(item)}
                          onChange={() => internalFilters.toggleItem(item)}
                        />
                      </MenuListItem>
                    ))}
                  </AccordionItem>
                ),
            )}
          </MenuList>
        </Popup>
      </>
    ),
    [openPopups, activeFilters, internalFilters, filters, selectedPopUp],
  );

  return filterType === 'page' ? (
    <>
      <Media at={['xs', 'sm']}>{renderSmallFilters}</Media>
      <Media at={'md'}>{renderMediumFilters}</Media>
      <Media at={['lg', 'xl', 'xxl']}>{renderDesktopFilters}</Media>
    </>
  ) : (
    <>
      <Media at={['xs', 'sm']}>{renderSmallFilters}</Media>
      <Media at={['md', 'lg', 'xl', 'xxl']}>{renderDesktopFilters}</Media>
    </>
  );
};
