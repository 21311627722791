import { Banner, LanguageSelector, Typo } from '@dreipol/pusch-components';
import clsx from 'clsx';
import Link from 'next/link';
import {
  PropsWithChildren,
  ReactElement,
  useLayoutEffect,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { PageLinkItem } from '../../services/get-pages';
import { Container } from '../container/container';
import { CookieBanner } from '../cookie-banner/cookie-banner';
import { Header } from '../header/header';
import classes from './layout.module.scss';
import { MapArticleView } from '../map-article-view/map-article-view';
import { useRouter } from 'next/dist/client/router';
import { i18n, useTranslation } from 'next-i18next';
import { FALLBACK_LOCALE } from 'config';
import { DatoLocalizedSlug } from 'types/dato';
import { useSearchParams } from 'src/hooks/use-search-params';
import { useArticleFilterContext } from 'src/context/article-filter-context';

export type LayoutProps = PropsWithChildren<{
  /**
   * Custom header component
   */
  pages: PageLinkItem[];

  /**
   * List of available pages
   */
  localizedPageSlug?: DatoLocalizedSlug[];

  /**
   * Custom footer component
   */
  footer?: ReactElement;

  /**
   * Custom className
   */
  className?: string;

  /**
   * Custom container classname
   */
  containerClassname?: string;
}>;

/**
 * Base layout component
 */
export const Layout = ({
  pages,
  footer,
  children,
  className,
  containerClassname,
  localizedPageSlug,
}: LayoutProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { query } = router;
  const searchParams = useSearchParams();
  const { practiceMap } = router.query;
  const [selectedLang, setSelectedLang] = useState('DE');
  const [showLanguageBanner, setShowLanguageBanner] = useState(false);

  const handleLanguageSelection = useCallback(
    (lang: string) => {
      //get path of selected language
      const localizedPath = localizedPageSlug?.find(
        (el) => el.locale === lang.toLowerCase(),
      );
      //if there is no path add missing translation query param
      const params =
        !localizedPath?.value && localizedPageSlug
          ? { 'missing-translation': true }
          : undefined;
      //switch page
      router.push(
        { pathname: `/${localizedPath?.value ?? ''}`, query: params },
        undefined,
        {
          locale: lang.toLowerCase(),
        },
      );
      setSelectedLang(lang);
    },
    [localizedPageSlug, router],
  );

  const handleBannerClick = () => {
    searchParams.remove(['missing-translation']);
  };

  useEffect(() => {
    setShowLanguageBanner(
      searchParams.get('missing-translation') ? true : false,
    );
  }, [query]);

  useEffect(() => {
    setSelectedLang((i18n?.language ?? FALLBACK_LOCALE).toUpperCase());
  }, []);
  const { activeFilter, searchParam, scrollingPos, setScrollingPos } =
    useArticleFilterContext()!;

  const closeMap = () => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...(router.query['slug'] && { slug: router.query['slug'] }),
          ...(activeFilter &&
            activeFilter.length > 0 && { filterIds: activeFilter }),
          ...(searchParam !== '' && { q: searchParam }),
        },
      },
      undefined,
      { shallow: false, scroll: false },
    );
    if (scrollingPos) scrollTo({ top: scrollingPos });
  };

  useLayoutEffect(() => {
    return () => setScrollingPos(window.scrollY);
  }, [practiceMap]);

  return (
    <div className={clsx(classes.root, className)}>
      <div id="header">
        <Header
          className={classes.header}
          languageSwitcher={
            <LanguageSelector
              languages={['DE', 'FR']}
              selection={selectedLang}
              onClick={handleLanguageSelection}
            ></LanguageSelector>
          }
        >
          {pages?.map((link) => (
            <Link href={`/${link.slug}`} key={link.slug}>
              <Typo color={'secondary'}>{link.title}</Typo>
            </Link>
          ))}
        </Header>
      </div>
      {showLanguageBanner && (
        <Banner onClick={handleBannerClick}>
          <Typo variant="paragraph1">{t('banner.missing-translation')}</Typo>
        </Banner>
      )}

      <Container className={containerClassname}>
        <main className={classes.main}>{children}</main>
      </Container>
      {footer}
      <CookieBanner></CookieBanner>
      {practiceMap === 'true' && (
        <MapArticleView open={practiceMap === 'true'} onClose={closeMap} />
      )}
    </div>
  );
};
