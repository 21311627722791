import { Typo } from '@dreipol/pusch-components';
import { MarkerF, OverlayView } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { DatoFilter } from '../../../types/dato';
import classes from './map-marker.module.scss';

export type MapMarkerProps = {
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  opacity?: number;
  lat: number;
  lng: number;
  rubric?: DatoFilter;
  pointOfView: DatoFilter[];
  clusterer?: Clusterer;
  active?: boolean;
  tooltipTitle: string;
  showTooltip: boolean;
};

export const MapMarker = ({
  lat,
  lng,
  onClick,
  onMouseOver,
  onMouseOut,
  opacity = 1,
  clusterer,
  pointOfView,
  rubric,
  active,
  tooltipTitle,
  showTooltip,
}: MapMarkerProps) => {
  const isCollab = useCallback(() => {
    const hasCommunity = pointOfView.some((filter) => {
      return filter.term === 'Gemeinde';
    });
    const hasSchool = pointOfView.some((filter) => {
      return filter.term === 'Schule';
    });
    const hasCorp = pointOfView.some((filter) => {
      return filter.term === 'Unternehmen';
    });
    return hasCommunity && hasSchool && hasCorp;
  }, [pointOfView]);

  const iconUrl = useMemo(() => {
    const path = isCollab() ? 'collab' : pointOfView[0].category;
    return `${process.env.NEXT_PUBLIC_APP_PATH}/assets/imgs/article/praxiskarte-marker-${path}-${rubric?.color}.png`;
  }, [active, rubric]);

  return (
    <>
      <MarkerF
        clusterer={clusterer}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        icon={{
          url: iconUrl,
          scaledSize: new google.maps.Size(
            isCollab() ? 56 : 48,
            isCollab() ? 84 : 78,
            'px',
            'px',
          ),
          origin: new google.maps.Point(0, -17), // origin
        }}
        options={{
          opacity: opacity,
        }}
        position={{
          lat,
          lng,
        }}
      />
      <OverlayView
        position={{
          lat: lat,
          lng: lng,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div
          className={clsx(classes.tooltip, {
            [classes.showTooltip]: showTooltip,
            [classes.hideTooltip]: !showTooltip,
          })}
        >
          <Typo variant="label2">{tooltipTitle}</Typo>
        </div>
      </OverlayView>
    </>
  );
};
