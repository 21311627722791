import { AccordionItem, Typo } from '@dreipol/pusch-components';
import { SyntheticEvent, useState } from 'react';
import { GlossaryGroup } from '../../../pages/api/glossary';
import { DatoGlossaryEntry } from '../../../types/dato';
import { useResource } from '../../hooks/use-resource';
import { useSearchParams } from '../../hooks/use-search-params';
import { RecursiveContent } from '../recursive-content/recursive-content';
import { Stack } from '../stack/stack';
import classes from './glossary-accordion.module.scss';
import { FALLBACK_LOCALE } from 'config';

export type GlossaryAccordionProps = {
  title: string;
  locale?: string;
};

export const GlossaryAccordion = ({
  title,
  locale,
}: GlossaryAccordionProps) => {
  const glossaryApi = useResource<GlossaryGroup[]>({
    url: `/api/glossary`,
    params: {
      locale: locale ?? FALLBACK_LOCALE,
    },
  });

  const searchPrams = useSearchParams(true);
  const [initialTarget] = useState(searchPrams.get('id'));

  const onOpenItem = (
    event: SyntheticEvent<HTMLDetailsElement>,
    item: DatoGlossaryEntry,
  ) => {
    searchPrams.set({ id: item.id.toString() });
    scrollTo({
      behavior: 'smooth',
      top: (event.target as HTMLElement).offsetTop - 70,
    });
  };

  return (
    <Stack space={6} className={classes.root}>
      <Typo variant={'h1'} centered>
        {title}
      </Typo>

      <div className={classes.glossaryContainer}>
        {glossaryApi.data?.map((group) => {
          return (
            <Stack space={0.5} key={group.title}>
              <Typo variant={'h2'}>{group.title}</Typo>
              {group.items.map((entry) => (
                <AccordionItem
                  key={entry.id}
                  open={entry.id === initialTarget}
                  color={'primary'}
                  header={<Typo>{entry.title}</Typo>}
                  onOpen={(event) => onOpenItem(event, entry)}
                >
                  <div className={classes.accordionContent}>
                    <RecursiveContent
                      data={entry.description}
                      paragraphTypoVariant={'paragraph2'}
                    />
                  </div>
                </AccordionItem>
              ))}
            </Stack>
          );
        })}
      </div>
    </Stack>
  );
};
