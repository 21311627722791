import Script from 'next/script';
import classes from './donation-form.module.scss';
import { useRouter } from 'next/router';
import { FALLBACK_LOCALE } from 'config';
import { useEffect } from 'react';

export const DonationForm = () => {
  const router = useRouter();

  const runWidget = () => {
    (window as any)?.rnw?.tamaro?.runWidget('.rnw-widget-container', {
      paymentWidgetBlocks: [
        'payment_amounts_and_intervals',
        'payment_payment_methods',
        'payment_profile',
        'payment_address',
      ],
      language: router.locale ?? FALLBACK_LOCALE,
      amounts: [50, 70, 100, 150],
    });

    (window as any)?.rnw?.tamaro?.events['paymentComplete'].subscribe(function (
      event: unknown,
    ) {
      (window as any)?.dataLayer.push({
        event: 'paymentComplete',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        daten: event.data.api,
      });
    });
  };

  useEffect(() => {
    runWidget();
  }, [router.locale]);

  return (
    <div className={classes.root}>
      <Script
        strategy="beforeInteractive"
        src="https://tamaro.raisenow.com/pusch-6422/latest/widget.js"
        onReady={runWidget}
      />
      <div className="rnw-widget-container"></div>
    </div>
  );
};
