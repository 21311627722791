import { Typo } from '@dreipol/pusch-components';
import clsx from 'clsx';
import Image from 'next/image';
import { DatoResponsiveImage } from 'types/dato';
import { MediaSource } from '../media-source/media-source';
import classes from './structured-text-image.module.scss';

export type StructuredTextImageProps = {
  /**
   * Image data
   */
  image: DatoResponsiveImage;

  /**
   * Aspect ratio of image
   */
  aspectRatio: string;

  /**
   * Has image legend
   */
  showLegend?: boolean;
};

export const StructuredTextImage = ({
  image,
  aspectRatio,
  showLegend,
}: StructuredTextImageProps) => {
  //map ratio to classname
  const ratioStrings = {
    '3:2': 'threeToTwo',
    '1:1': 'oneToOne',
    '2:3': 'twoToThree',
  };

  /**
   * Returns image url
   */
  const getImageUrl = (ratio: string): string | undefined => {
    switch (ratio) {
      case '3:2':
        return image.threeToTwo;
      case '2:3':
        return image.twoToThree;
      case '1:1':
        return image.oneToOne;
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        classes[ratioStrings[aspectRatio as keyof typeof ratioStrings]],
      )}
    >
      <div
        className={clsx(
          classes.imageContainer,
          classes[ratioStrings[aspectRatio as keyof typeof ratioStrings]],
        )}
      >
        <Image
          src={getImageUrl(aspectRatio) ?? ''}
          alt={image.alt ?? image.title ?? ''}
          width="0"
          height="0"
          sizes="100vw"
          className={clsx(
            classes[ratioStrings[aspectRatio as keyof typeof ratioStrings]],
            classes.image,
          )}
        ></Image>
        {showLegend && (
          <Typo className={classes.caption} variant="paragraph2">
            {image.title}
            <MediaSource
              mediaType="image"
              sourceLink={image.customData?.sourceLink}
              sourceName={image.customData?.sourceName}
            ></MediaSource>
          </Typo>
        )}
      </div>
    </div>
  );
};
