'use client';
import { LoadingIndicator } from '@dreipol/pusch-components';
import {
  GoogleMap,
  MarkerClustererF,
  useLoadScript,
} from '@react-google-maps/api';
import { Cluster } from '@react-google-maps/marker-clusterer';
import { Easing, Tween, update } from '@tweenjs/tween.js';
import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import MapMouseEvent = google.maps.MapMouseEvent;

const containerStyle = {
  width: '100%',
  height: '100%',
};

export type GeoLocation = {
  lat: number;
  lng: number;
};

export type MapProps = PropsWithChildren<{
  location: google.maps.LatLngLiteral;
  apiKey: string;
  mapId: string;
  clustering?: boolean;
  zoom?: number;
  onClick?: (e: MapMouseEvent) => void;
  className?: string;
  onBoundsChangedProp?: (
    bounds: google.maps.LatLngBounds | undefined,
    mapCenter: google.maps.LatLng | undefined,
  ) => void;
}>;

export const Map = ({
  className,
  location,
  apiKey,
  onBoundsChangedProp,
  onClick,
  children,
  zoom,
  clustering,
  mapId,
}: MapProps) => {
  const [key, setKey] = useState('');
  const [center, setCenter] = useState(location);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    mapIds: [mapId],
  });

  const options: google.maps.MapOptions = useMemo(
    () => ({
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      minZoom: 6,
      maxZoom: 14,
      zoom: 9,
      isFractionalZoomEnabled: true,
      mapId: mapId,
    }),
    [mapId],
  );

  useEffect(() => {
    setCenter(location);
  }, [location]);

  const onBoundsChanged = useCallback(() => {
    if (!map) {
      return;
    }
    onBoundsChangedProp?.(map.getBounds(), map.getCenter());
  }, [onBoundsChangedProp, map]);

  const onLoad = useCallback(
    (m: google.maps.Map) => {
      setMap(m);
      onBoundsChangedProp?.(m.getBounds(), m.getCenter());
    },
    [onBoundsChangedProp],
  );

  useLayoutEffect(() => {
    setTimeout(() => {
      setKey('cluster');
    }, 500);
  }, []);

  const animate = (time: number) => {
    requestAnimationFrame(animate);
    update(time);
  };

  const onClusterClicked = (cluster: Cluster) => {
    if (!map) {
      return;
    }
    const bounds = map.getBounds()!;

    new Tween(bounds)
      .to(cluster.bounds!, 400)
      .easing(Easing.Exponential.InOut)
      .onUpdate(() => {
        map?.fitBounds(bounds);
      })
      .start();
    requestAnimationFrame(animate);
  };

  if (loadError) {
    return (
      <div>Map cannot be loaded right now, sorry. Try reloading the page.</div>
    );
  }

  if (!isLoaded) {
    return <LoadingIndicator />;
  }

  return (
    <GoogleMap
      mapContainerClassName={className}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      options={options}
      onBoundsChanged={onBoundsChanged}
      onLoad={onLoad}
      onClick={(e) => onClick?.(e)}
    >
      {clustering ? (
        <MarkerClustererF
          key={key}
          averageCenter
          onClick={onClusterClicked}
          zoomOnClick={false}
          gridSize={20}
          enableRetinaIcons
          styles={[
            {
              url: `${process.env.NEXT_PUBLIC_APP_PATH}/assets/imgs/cluster/m1.svg`,
              width: 48,
              height: 48,
              textSize: 16,
            },
          ]}
        >
          {(clusterer) => (
            <>
              {React.Children.map(
                children as ReactElement[],
                (child: ReactElement) =>
                  child
                    ? cloneElement(child, {
                        ...child.props,
                        clusterer,
                      })
                    : null,
              )}
            </>
          )}
        </MarkerClustererF>
      ) : (
        <>{children}</>
      )}
    </GoogleMap>
  );
};
