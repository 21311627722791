import { Portal } from '@dreipol/pusch-components';
import clsx from 'clsx';
import { PropsWithChildren, useEffect } from 'react';
import classes from './overlay.module.scss';

export type OverlayProps = PropsWithChildren<{
  onClose?: () => void;
  open?: boolean;
  className?: string;
  variant?: 'fullscreen' | 'centered' | 'fill';
}>;

export const Overlay = ({
  children,
  open,
  className,
  variant = 'centered',
}: OverlayProps) => {
  useEffect(() => {
    const body = document.body;
    if (open) {
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
    } else {
      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Portal target={'#modalPortal'}>
      <div className={clsx(classes.root, classes[variant])}>
        <div className={classes.background} />
        <div className={clsx(classes.contentWrapper)}>
          <div className={clsx(classes.content, className)}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};
