import { Typo } from '@dreipol/pusch-components';
import clsx from 'clsx';
import { useMemo } from 'react';
import { DatoVideo } from 'types/dato';
import { MediaSource } from '../media-source/media-source';
import classes from './structured-text-video.module.scss';

export type StructuredTextVideoProps = {
  /**
   * Video data
   */
  video: DatoVideo;

  /**
   * Video caption
   */
  description?: string;

  /**
   * Display name of source
   */
  sourceName?: string;

  /**
   * Link to source
   */
  sourceLink?: string;

  /**
   * Enable various embedded formats
   */
  additionalFormats: string;

  /**
   * Origin of video
   */
  plattform: string;

  /**
   * Show video as heading or as text embed
   */
  type: 'text' | 'heading';
};

export const StrucutredTextVideo = ({
  video,
  description,
  additionalFormats,
  plattform,
  sourceLink,
  sourceName,
  type,
}: StructuredTextVideoProps) => {
  const embeddedLinks = {
    youtube: `https://www.youtube.com/embed/${video?.providerUid}${
      type === 'heading' ? '?autoplay=1&enablejsapi=1' : '?enablejsapi=1'
    }`,
    vimeo: `https://player.vimeo.com/video/${video?.providerUid}${
      type === 'heading' ? '?autoplay=1&transparent=0' : ''
    }`,
    facebook: `https://www.facebook.com/plugins/video.php?&href=https%3A%2F%2Fwww.facebook.com%2Ffacebookwatch%2Fvideos%2F${video?.providerUid}%2F&t=1`,
    other: additionalFormats,
  };
  const source = useMemo(
    () =>
      embeddedLinks[
        ((video?.provider ?? plattform) as keyof typeof embeddedLinks) ??
          ('other' as keyof typeof embeddedLinks)
      ],
    [plattform],
  );

  return (
    <div className={classes[`${type}VideoRoot`]}>
      <iframe
        className={clsx(
          { [classes.textVideo]: type === 'text' },
          classes.video,
          classes[video?.provider],
        )}
        src={source}
        allowFullScreen
        allow="autoplay"
      ></iframe>
      {type === 'text' && (
        <Typo className={classes.caption} variant="paragraph2">
          {description}
          <MediaSource
            mediaType="other"
            sourceLink={sourceLink}
            sourceName={sourceName}
          ></MediaSource>
        </Typo>
      )}
    </div>
  );
};
