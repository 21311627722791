import { AccordionItem, Typo } from '@dreipol/pusch-components';
import { StructuredText } from 'datocms-structured-text-utils';
import { DatoPlugins } from 'types/dato-plugins';
import { RecursiveContent } from '../recursive-content/recursive-content';
import classes from './structured-text-accordion.module.scss';

export type StructuredTextAccordionProps = {
  /**
   * Title
   */
  title: string;
  /**
   * Content
   */
  content: StructuredText<DatoPlugins>;
};

export const StructuredTextAccordion = ({
  title,
  content,
}: StructuredTextAccordionProps) => {
  return (
    <div className={classes.root}>
      <AccordionItem
        filled
        color="primary"
        header={<Typo variant="h6">{title}</Typo>}
      >
        <div className={classes.accordionContent}>
          <RecursiveContent
            paragraphTypoVariant="paragraph2"
            data={content}
          ></RecursiveContent>
        </div>
      </AccordionItem>
    </div>
  );
};
