import { ARTICLE_ROUTE_PREFIX, AUTHOR_ROUTE_PREFIX } from 'config';

export const getAuthorDetailRoute = (id: string, locale: string) => `${locale === 'fr' ? '/fr' : ''}/${locale === "de" ? AUTHOR_ROUTE_PREFIX.de : AUTHOR_ROUTE_PREFIX.fr}/${id}`;
export const getArticleRoute = (id: string, locale: string) => `${locale === 'fr' ? '/fr' : ''}/${locale === "de" ? ARTICLE_ROUTE_PREFIX.de : ARTICLE_ROUTE_PREFIX.fr}/${id}`;
export const getFilterRoute = (id: string | string[]) => {
  if (Array.isArray(id)) {
    return id.reduce((url, item) => {
      return `${url}filterIds=${item}&`;
    }, '/?');
  }
  return `/?filterIds=${id}`;
};
