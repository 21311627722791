import { StructuredText } from 'datocms-structured-text-utils';
import { DatoPlugins } from 'types/dato-plugins';
import { RecursiveContent } from '../recursive-content/recursive-content';
import classes from './structured-text-secondary-text.module.scss';

export type StructuredTextSecondaryTextProps = {
  /**
   * structured text
   */
  text: StructuredText<DatoPlugins>;
};

export const StructuredTextSecodaryText = ({
  text,
}: StructuredTextSecondaryTextProps) => {
  return (
    <div className={classes.root}>
      <RecursiveContent data={text}></RecursiveContent>
    </div>
  );
};
