import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import classes from './artifact.module.scss';
import { DatoColor } from 'types/dato';

export type ArtifactProps = PropsWithChildren<{
  className?: string;
  color?: DatoColor;
  variant: 'contained' | 'appearOnHover' | 'fixed';
  active?: boolean;
  fill?: boolean;
}>;

export const Artifact = ({
  children,
  className,
  color,
  variant,
  active,
  fill,
}: ArtifactProps) => {
  return (
    <div
      className={clsx(
        classes.root,
        color && classes[color],
        className,
        classes[variant],
        {
          [classes.active]: active,
          [classes.fill]: fill,
        },
      )}
    >
      <div className={clsx(classes.element, classes[variant])} />
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
};
