'use client';

import {
  Button,
  Chip,
  CloseIcon,
  IconButton,
  LoadingIndicator,
} from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { useSelectionList } from '@dreipol/t3-react-utils';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIdList } from 'src/hooks/use-id-list';
import { getArticleRoute } from 'src/routes';
import { DatoArticleWithLocation } from '../../../types/dato';
import { useResource } from '../../hooks/use-resource';
import { useSearchParams } from '../../hooks/use-search-params';
import { FilterGroup, FilterGroupItem } from '../../services/get-filters';
import { getPointOfView, getRubric } from '../../utils/get-filter-by-type';
import { isWithinBounds } from '../../utils/is-within-bounds';
import { DynamicFilterRenderer } from '../dynamic-filter-renderer/dynamic-filter-renderer';
import { MapArticleList } from '../map-article-list/map-article-list';
import { MapMarker } from '../map-marker/map-marker';
import { GeoLocation, Map } from '../map/map';
import { Overlay } from '../overlay/overlay';
import classes from './map-article-view.module.scss';
import { useRouter } from 'next/router';
import { MapLegend } from '../map-legend/map-legend';
import { FALLBACK_LOCALE } from 'config';
import { useArticleFilterContext } from 'src/context/article-filter-context';

const defaultCenter = {
  lat: 46.9558185,
  lng: 7.7716321,
};

export type MapArticleViewProps = {
  open?: boolean;
  onClose: () => void;
};

export const MapArticleView = ({ open, onClose }: MapArticleViewProps) => {
  const [hoveringPin, setHoveringPin] = useState<string | null>(null);
  const [selectedPin, setSelectedPin] = useState<string | null>(null);
  const [bounds, setBounds] = useState<[GeoLocation, GeoLocation] | null>(null);
  const searchParams = useSearchParams(true);
  const isSm = useMediaQuery(['sm', 'xs']);
  const isLarge = useMediaQuery(['xl', 'xxl']);
  const { t } = useTranslation();
  const router = useRouter();
  const list = useSelectionList<string>();
  const renderSlider = useMediaQuery(['xs', 'sm', 'md']);
  const filtersApi = useResource<FilterGroup[]>({
    url: '/api/filters',
    params: { locale: router.locale },
  });
  const articlesApi = useResource<DatoArticleWithLocation[]>({
    url: '/api/articles/map',
    params: {
      limit: 100,
      filterIds: list.list,
      locale: router.locale,
    },
  });
  const [currentlyHoveredMarker, setCurrentlyHoveredMarker] = useState('');
  const { activeFilterPracticeMap, setActiveFilterPracticeMap } =
    useArticleFilterContext()!;

  const togglePin = (id: string | null) => {
    if (!isLarge) {
      if (selectedPin === id) {
        setSelectedPin(null);
        searchParams.remove(['article']);
        return;
      }
      setSelectedPin(id);
      if (id !== null) {
        searchParams.set({ article: id, practiceMap: 'true' });
      } else {
        searchParams.remove(['article']);
      }
    }
  };

  useEffect(() => {
    searchParams.set({
      filterIds: list.list,
    });
    setActiveFilterPracticeMap(list.list);

    return () => setActiveFilterPracticeMap(null);
  }, [list.list]);

  const articles = useMemo(
    () =>
      articlesApi.data
        ?.filter((a) => (selectedPin ? a.id === selectedPin : true))
        .filter((a) => getRubric(a.filters) && getPointOfView(a.filters)),
    [articlesApi.data, selectedPin],
  );

  const filteredArticles = useMemo(() => {
    if (!bounds) {
      return articles ?? [];
    }

    return (articles ?? []).filter((article) =>
      isWithinBounds(
        {
          lat: article.geoLocation.latitude,
          lng: article.geoLocation.longitude,
        },
        bounds,
      ),
    );
  }, [bounds, articles]);

  const onHoverArticle = useCallback((articleId: string | null) => {
    setHoveringPin(articleId);
  }, []);

  const onBoundsChange = useCallback((b?: google.maps.LatLngBounds) => {
    if (!b) {
      setBounds(null);
      return;
    }
    setBounds([
      { lat: b.getSouthWest().lat(), lng: b.getSouthWest().lng() },
      {
        lat: b.getNorthEast().lat(),
        lng: b.getNorthEast().lng(),
      },
    ]);
  }, []);

  useEffect(() => {
    if (searchParams.get('article')) {
      setSelectedPin(searchParams.get('article'));
    }
    //set filters from query
    const params = router.query;
    const filterIds = params.filterIds
      ? typeof params.filterIds === 'string'
        ? [params.filterIds]
        : params.filterIds
      : [];
    list.setList(activeFilterPracticeMap ?? filterIds);
  }, []);

  const filterList = useIdList<FilterGroupItem, string>(
    (filtersApi.data ?? []).reduce((items, item) => {
      return items.concat(item.items);
    }, [] as FilterGroupItem[]),
    [filtersApi.data],
  );

  return (
    <Overlay
      open={open}
      variant={renderSlider ? 'fullscreen' : 'fill'}
      className={classes.root}
    >
      <div className={classes.header}>
        <div className={classes.filterContainer}>
          <div className={classes.filterDropdowns}>
            <DynamicFilterRenderer
              filterType="map"
              popUpPosition="left"
              filters={filtersApi.data ?? []}
              activeFilters={list.list}
              onChange={(l) => list.setList(l.map((i) => i.id))}
              onClear={() => list.clear()}
            />
          </div>
          {list.list.length !== 0 && !isSm && (
            <div className={classes.activeFilters}>
              {list.list.map((id) => {
                const item = filterList.get(id);
                if (!item) {
                  return null;
                }
                return (
                  <Chip
                    color={item.color || 'default'}
                    key={item.id}
                    onClick={() => {
                      list.remove(item.id);
                    }}
                  >
                    {item.term}
                  </Chip>
                );
              })}
              {
                <Button
                  inline
                  variant={'link'}
                  typoVariant={'textlink'}
                  onClick={() => {
                    list.clear();
                  }}
                >
                  {t('filter.reset_all')}
                </Button>
              }
            </div>
          )}
        </div>
        <div>
          <IconButton
            variant="outlined"
            onClick={() => {
              setSelectedPin(null);
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.mapWrapper}>
          <Map
            className={classes.map}
            zoom={8}
            location={defaultCenter}
            apiKey={process.env.NEXT_PUBLIC_GMAPS_API ?? ''}
            mapId={process.env.NEXT_PUBLIC_GMAPS_MAP_ID ?? ''}
            clustering
            onBoundsChangedProp={onBoundsChange}
          >
            {articles?.map((article) => (
              <MapMarker
                key={article.id}
                lat={article.geoLocation.latitude}
                lng={article.geoLocation.longitude}
                onClick={() => {
                  togglePin?.(article.id);
                  if (isLarge)
                    window.open(
                      (process.env.VERCEL_URL ?? ``) +
                        `${process.env.NEXT_PUBLIC_APP_PATH}` +
                        getArticleRoute(
                          article.slug,
                          router.locale ?? FALLBACK_LOCALE,
                        ),
                    );
                }}
                onMouseOver={() => setCurrentlyHoveredMarker(article.id)}
                onMouseOut={() => setCurrentlyHoveredMarker('')}
                rubric={getRubric(article.filters)}
                pointOfView={getPointOfView(article.filters)}
                active={
                  selectedPin === article.id || hoveringPin === article.id
                }
                tooltipTitle={article.title}
                showTooltip={currentlyHoveredMarker === article.id && isLarge}
              />
            ))}
          </Map>
          {selectedPin && (articlesApi.data ?? []).length > 1 && (
            <Button
              className={classes.selectionResetButton}
              variant={'contained'}
              onClick={() => togglePin(null)}
            >
              {t('filter.show_all')}
            </Button>
          )}
          <MapLegend
            className={classes.legend}
            showButton={filtersApi.data?.some(
              (group) => group.id === 'point-of-view' && group.items.length > 1,
            )}
          />
        </div>

        {articlesApi.isLoading ? (
          <LoadingIndicator />
        ) : (
          <MapArticleList
            className={clsx(classes.articleList)}
            hoveringId={filteredArticles.length > 1 ? hoveringPin : null}
            articles={filteredArticles ?? []}
            onHover={(id) => onHoverArticle(id)}
          />
        )}
      </div>
    </Overlay>
  );
};
