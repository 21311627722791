import { createContext, useContext } from 'react';
import { DatoArticle } from '../../types/dato';

export type ArticleListContextType = {
  articles: DatoArticle[];
};

const ArticleListContextValue: ArticleListContextType = {
  articles: [],
};

export const ArticleListContext = createContext(ArticleListContextValue);

export const useArticleListContext = () => useContext(ArticleListContext);
