import { Typo } from '@dreipol/pusch-components';
import clsx from 'clsx';
import { StructuredText } from 'datocms-structured-text-utils';
import Link from 'next/link';
import { PropsWithChildren, ReactNode } from 'react';
import { DatoPlugins } from 'types/dato-plugins';
import { Artifact } from '../artifact/artifact';
import { Container } from '../container/container';
import { RecursiveContent } from '../recursive-content/recursive-content';
import classes from './focus-article.module.scss';
import { DatoColor } from 'types/dato';

export type FocusArticleProps = PropsWithChildren<{
  /**
   * Title of the component
   */
  title: string;

  /**
   * List of tags
   */
  tags?: ReactNode;

  /**
   * Custom CTA shown below the content
   */
  cta?: ReactNode;

  /**
   * Color of the article
   */
  color?: DatoColor;

  /**
   * Article URL
   */
  href: string;

  content: StructuredText<DatoPlugins> | string;
}>;
export const FocusArticle = ({
  content,
  title,
  tags,
  cta,
  color = 'blue',
  href,
}: FocusArticleProps) => {
  return (
    <Artifact
      className={classes.artifactContainer}
      color={color}
      variant={'contained'}
    >
      <Link href={href}>
        <article className={clsx(classes.root, classes[color])}>
          <Container fullWidth className={classes.content}>
            <div className={classes.headerWrapper}>
              <Typo
                variant={'h2'}
                className={classes.title}
                color={'textPrimary'}
              >
                {title}
              </Typo>
              <div className={classes.tagWrapper}>{tags}</div>
            </div>
            <div className={classes.leadContainer}>
              {typeof content === 'string' ? (
                <Typo color="textPrimary" variant="paragraph2">
                  {content}
                </Typo>
              ) : (
                <RecursiveContent
                  className={classes.text}
                  data={content}
                  paragraphTypoVariant={'paragraph2'}
                  typoProps={{ color: 'textPrimary' }}
                />
              )}
              <div className={classes.ctaWrapper}>{cta}</div>
            </div>
          </Container>
        </article>
      </Link>
    </Artifact>
  );
};
