import { Typo, TypoProps } from '@dreipol/pusch-components';
import { PropsWithChildren } from 'react';
import classes from './quote.module.scss';

export type QuoteProps = PropsWithChildren<
  Omit<TypoProps, 'ref'> & { quotationMarks: boolean }
>;

export const Quote = ({ children, quotationMarks, ...rest }: QuoteProps) => {
  return (
    <Typo {...rest} color={'primary'} className={classes.root}>
      {quotationMarks ? ` «${children}» ` : children}
    </Typo>
  );
};
