import { Typo } from '@dreipol/pusch-components';
import Link from 'next/link';
import { PropsWithChildren, ReactNode } from 'react';
import classes from './teaser.module.scss';

export type TeaserProps = PropsWithChildren<{
  title: string;
  cta?: ReactNode;
  href: string;
  external: boolean | undefined;
}>;

export const Teaser = ({
  title,
  children,
  cta,
  href,
  external,
}: TeaserProps) => {
  return (
    <Link
      target={external ? '_blank' : '_self'}
      href={href}
      className={classes.root}
    >
      <article className={classes.body}>
        <Typo
          className={classes.title}
          color="textPrimary"
          variant={'h2'}
          as={'h2'}
        >
          {title}
        </Typo>
        {children}
        <div className={classes.ctaWrapper}>{cta}</div>
      </article>
    </Link>
  );
};
