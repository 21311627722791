import { Button, Typo } from '@dreipol/pusch-components';
import classes from './media-source.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

export type MediaSourceProps = {
  /**
   * Display name of source
   */
  sourceName?: string;

  /**
   * Link to source
   */
  sourceLink?: string;

  /**
   * Link to source
   */
  mediaType: 'image' | 'other';
};

export const MediaSource = ({
  sourceName,
  sourceLink,
  mediaType,
}: MediaSourceProps) => {
  const { t } = useTranslation();

  return (
    <>
      {sourceName && (
        <Typo variant="paragraph2" as="span" color="textSecondary">
          {t(`media-source-${mediaType}.sourceWithAuthor`, {
            name: sourceLink ? '' : sourceName,
          })}
        </Typo>
      )}
      {sourceLink && (
        <Button
          typoVariant="paragraph2"
          as={sourceLink ? 'a' : 'button'}
          inline
          disableHover
          variant={sourceLink ? 'link' : 'text'}
          href={sourceLink ?? ''}
          target="_blank"
          className={clsx(classes.sourceLink, {
            [classes.linkOnly]: !sourceName,
          })}
        >
          {sourceName ? sourceName : t(`media-source-${mediaType}.source`)}
        </Button>
      )}
    </>
  );
};
