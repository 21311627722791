import { ArrowRightIcon, Button, Typo } from '@dreipol/pusch-components';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useState } from 'react';
import { MapArticleView } from '../map-article-view/map-article-view';
import classes from './map-cta.module.scss';

export const MapCta = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Button
        color={'primary'}
        endAdornment={<ArrowRightIcon />}
        onClick={() => setOpen(true)}
      >
        {t('map.open_button_label')}
      </Button>
      <MapArticleView open={open} onClose={() => setOpen(false)} />

      <div className={classes.legend}>
        <Typo variant={'h5'} className={classes.legendTitle} centered>
          {t('map.legend.title')}
        </Typo>
        <div className={classes.icons}>
          <div className={classes.iconWrapper}>
            <Image
              alt={'School image'}
              width={48}
              height={65}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/schule.svg`}
            />
            <Typo variant={'label2'} color={'textSecondary'} centered>
              {t('map.legend.school_label')}
            </Typo>
          </div>

          <div className={classes.iconWrapper}>
            <Image
              alt={'School image'}
              width={48}
              height={65}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/collab.svg`}
            />
            <Typo variant={'label2'} color={'textSecondary'} centered>
              {t('map.legend.collab_label')}
            </Typo>
          </div>

          <div className={classes.iconWrapper}>
            <Image
              alt={'School image'}
              width={48}
              height={65}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/gemeinde.svg`}
            />
            <Typo variant={'label2'} color={'textSecondary'} centered>
              {t('map.legend.community_label')}
            </Typo>
          </div>
        </div>
      </div>
    </div>
  );
};
