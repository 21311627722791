import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_PATH,
});

export type SwrFetcherProps = { url: string | null; params: unknown };

const exec = (config: SwrFetcherProps) => {
  if (!config.url) {
    return Promise.resolve();
  }

  return instance
    .get(config.url, { params: config.params })
    .then((res) => res.data)
    .catch((e) => {
      console.error(e);
      throw e;
    });
};

export const swrFetcher = <T>(config: SwrFetcherProps): Promise<T> => {
  return exec(config) as Promise<T>;
};
