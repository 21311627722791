import { Typo } from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { StructuredText } from 'datocms-structured-text-utils';
import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';
import { DatoColor, DatoFilter } from '../../../types/dato';
import { DatoPlugins } from '../../../types/dato-plugins';
import { getArticleRoute } from '../../routes';
import { FilterIndicator } from '../filter-indicator/filter-indicator';
import { RecursiveContent } from '../recursive-content/recursive-content';
import classes from './reduces-article-list-item.module.scss';
import { useRouter } from 'next/router';
import { FALLBACK_LOCALE } from 'config';

export type ReducedArticleListItemProps = {
  src: string;
  slug: string;
  title: string;
  alt?: string;
  date: string;
  color: DatoColor;
  filter?: DatoFilter;
  lead: StructuredText<DatoPlugins>;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};
export const ReducedArticleListItem = ({
  slug,
  src,
  title,
  date,
  filter,
  lead,
  alt,
  onMouseOver,
  onMouseOut,
}: ReducedArticleListItemProps) => {
  const isLarge = useMediaQuery(['xl', 'xxl']);
  const isMedium = useMediaQuery(['lg', 'md']);
  const isSmall = useMediaQuery(['xs', 'sm']);
  const imageSize = useMemo(() => {
    if (isLarge) return 164;
    else if (isMedium) return 140;
    else if (isSmall) return 90;
    else return 164;
  }, [isLarge, isMedium, isSmall]);
  const router = useRouter();

  return (
    <Link
      className={classes.root}
      href={getArticleRoute(slug, router.locale ?? FALLBACK_LOCALE)}
      target={'_blank'}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <Image
        src={src}
        alt={alt ?? title}
        width={imageSize}
        height={imageSize}
        className={classes.image}
      />
      <div>
        <div className={classes.metaWrapper}>
          <Typo variant="paragraph2">{date}</Typo>
          <FilterIndicator
            className={classes.indicator}
            color={filter?.color ?? 'blue'}
            label={filter?.term}
          />
        </div>
        <Typo className={classes.title} variant={'h6'} as={'h6'}>
          {title}
        </Typo>

        <RecursiveContent
          data={lead}
          paragraphTypoVariant={'paragraph2'}
          typoClassName={classes.label}
        />
      </div>
    </Link>
  );
};
