import {
  Avatar,
  Button,
  CheckIcon,
  Textfield,
  Typo,
} from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { StructuredText } from 'datocms-structured-text-utils';
import { useTranslation } from 'next-i18next';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { DatoResponsiveImage } from 'types/dato';
import { DatoPlugins } from 'types/dato-plugins';
import { RecursiveContent } from '../recursive-content/recursive-content';
import classes from './contact-form.module.scss';
import { useRouter } from 'next/router';

export type ContactFormProps = {
  /**
   * Dato image data for contact person
   */
  image: DatoResponsiveImage;
  /**
   * Name of contact
   */
  name: string;
  /**
   * Occupation of contact
   */
  occupation: string;
  /**
   * Contact text
   */
  text: StructuredText<DatoPlugins>;
  /**
   * Receiver of contact form email
   */
  receiverEmail: string;

  /**
   * Form Success Message
   */
  successMessage: string;
};

export const ContactForm = ({
  image,
  name,
  occupation,
  text,
  receiverEmail,
  successMessage,
}: ContactFormProps) => {
  const { t } = useTranslation();
  const successMessageRef = useRef<HTMLDivElement | null>(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const isSmallerMd = useMediaQuery(['xs', 'sm', 'md']);
  const [formData, setFormData] = useState({
    firstname: '',
    surname: '',
    email: '',
    phone: '',
    message: '',
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const router = useRouter();

  useEffect(() => {
    if (formSuccess) {
      successMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formSuccess]);

  const sendMail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    try {
      const token = await executeRecaptcha();
      const results = await fetch(
        `${process.env.NEXT_PUBLIC_APP_PATH}/api/mail-service`,
        {
          method: 'post',
          body: JSON.stringify({
            firstname: formData.firstname,
            surname: formData.surname,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
            receiver: receiverEmail,
            token: token,
          }),
        },
      );
      if (results.ok) {
        setFormSuccess(true);
      } else {
        alert(t('contact-form.alert'));
      }
    } catch (error) {
      console.log(error);
      alert(t('contact-form.alert'));
    }
  };

  return (
    <div className={classes.root}>
      <Typo variant="h1">{t('contact-form.title')}</Typo>
      <div className={classes.contactDetailsContainer}>
        <div>
          <Avatar
            alt={image.title ?? ''}
            url={image.oneToOne ?? ''}
            size={isSmallerMd ? 'xs' : 'sm'}
          ></Avatar>
        </div>
        <div>
          <Typo variant="paragraph1">{t('contact-form.contact')}</Typo>
          <Typo className={classes.name} variant="h3">
            {name}
          </Typo>
          <Typo variant="paragraph2">{occupation}</Typo>
        </div>
      </div>
      <div className={classes.textContainer}>
        <RecursiveContent data={text}></RecursiveContent>
      </div>
      <form
        onSubmit={(e) => {
          sendMail(e);
        }}
        method="post"
        className={classes.formContainer}
      >
        {formSuccess ? (
          <div className={classes.successMessage} ref={successMessageRef}>
            <Typo color="primary" variant="button">
              {successMessage}
            </Typo>{' '}
            <CheckIcon></CheckIcon>
          </div>
        ) : (
          <>
            <Textfield
              required
              name="contact-form"
              label={t('contact-form.firstname')}
              type={'text'}
              value={formData.firstname}
              placeholder={t('contact-form.placeholder', {
                field: t('contact-form.placeholder.firstname'),
              })}
              onChange={(value) =>
                setFormData({ ...formData, firstname: value })
              }
            />
            <Textfield
              required
              name="contact-form"
              label={t('contact-form.surname')}
              type={'text'}
              value={formData.surname}
              placeholder={t('contact-form.placeholder', {
                field: t('contact-form.placeholder.surname'),
              })}
              onChange={(value) => setFormData({ ...formData, surname: value })}
            />
            <Textfield
              required
              name="contact-form"
              label={t('contact-form.email')}
              type={'email'}
              value={formData.email}
              placeholder={t('contact-form.placeholder', {
                field: t('contact-form.placeholder.email'),
              })}
              onChange={(value) => setFormData({ ...formData, email: value })}
            />
            <Textfield
              name="contact-form"
              label={t('contact-form.phone', {
                optionalText: t('contact-form.optional'),
              })}
              type={'tel'}
              value={formData.phone}
              placeholder={t('contact-form.placeholder', {
                field: t('contact-form.placeholder.phone', {
                  optionalText: '',
                }),
              })}
              onChange={(value) => setFormData({ ...formData, phone: value })}
            />
            <Textfield
              required
              name="contact-form"
              className={classes.message}
              label={t('contact-form.message')}
              type={'textarea'}
              value={formData.message}
              placeholder={t('contact-form.placeholder', {
                field: t('contact-form.placeholder.message'),
              })}
              onChange={(value) => setFormData({ ...formData, message: value })}
            />
            <Typo
              className={classes.recaptcha}
              color="textSecondary"
              variant="label3"
            >
              {t('contact-form-recaptcha.start')}&nbsp;
              <Typo
                className={classes.inlineLinks}
                color="textPrimary"
                as="a"
                target="_blank"
                href="https://policies.google.com/privacy"
              >
                {t('contact-form-recaptcha.privacy-policy')}
              </Typo>
              &nbsp;{t('contact-form-recaptcha.and')}
              &nbsp;
              <Typo
                className={classes.inlineLinks}
                color="textPrimary"
                as="a"
                target="_blank"
                href="https://policies.google.com/terms"
              >
                {t('contact-form-recaptcha.term-of-use')}&nbsp;
              </Typo>
              {t('contact-form-recaptcha.end')}
            </Typo>
            <Button
              name="contact-form"
              type="submit"
              color="primary"
              className={classes.ctaButton}
            >
              {t('contact-form.cta')}
            </Button>
          </>
        )}
      </form>
    </div>
  );
};
