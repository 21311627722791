import {
  Button,
  FacebookIcon,
  IconButton,
  LinkedinIcon,
  PinterestIcon,
  Typo,
  YoutubeIcon,
} from '@dreipol/pusch-components';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useGlobalSettings } from 'src/context/global-settings-context';
import { DatoLink, DatoSocialMediaLink, PageLink } from 'types/dato';
import { Container } from '../container/container';
import classes from './footer.module.scss';

export type FooterProps = {
  /**
   * Name of company
   */
  companyName: string;
  /**
   * Description of company
   */
  companyDescription: string;
  /**
   * Company adress: street
   */
  street: string;
  /**
   * Additional adress info
   */
  addressAddition: string;
  /**
   * Zip Code
   */
  zip: string;
  /**
   * Company adress: city
   */
  city: string;
  /**
   * Company phone number
   */
  phoneNumber: string;
  /**
   * Company email adress
   */
  emailAddress: string;
  /**
   * Main content links
   */
  links: DatoLink[];
  /**
   * Legal links
   */
  legalLinks: PageLink[];
  /**
   * Social media links
   */
  socialMediaLinks: DatoSocialMediaLink[];
  /**
   * Contactform link
   */
  contactFormLink: { slug: string };
};

export const Footer = ({
  companyName,
  companyDescription,
  street,
  addressAddition,
  zip,
  city,
  phoneNumber,
  emailAddress,
  links,
  legalLinks,
  socialMediaLinks,
  contactFormLink,
}: FooterProps) => {
  const socialMediaIcons = {
    facebook: <FacebookIcon size="large"></FacebookIcon>,
    pinterest: <PinterestIcon size="large"></PinterestIcon>,
    linkedin: <LinkedinIcon size="large"></LinkedinIcon>,
    youtube: <YoutubeIcon size="large"></YoutubeIcon>,
  };
  const externalLinkIcon = (
    <Image
      src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/imgs/footer/external-link.svg`}
      alt="External Link Icon"
      width={24}
      height={24}
    />
  );
  const globalSettings = useGlobalSettings();
  const { t } = useTranslation();

  return (
    <footer className={classes.root}>
      <hr />
      <Container className={classes.footerContainer}>
        <div className={classes.topContainer}>
          <div>
            <Image
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/imgs/header/logo.svg`}
              alt="Logo"
              width={64}
              height={64}
            />
          </div>
          <Typo className={classes.contentCol} variant="paragraph2">
            <span>{companyName.toUpperCase()}</span>
            <span>{companyDescription}</span>
            <span>{street}</span>
            <span>{addressAddition}</span>
            <span>{zip + ' ' + city}</span>
          </Typo>
          <Typo className={classes.contentCol} variant="paragraph2">
            <Typo color="textPrimary" as="a" href={`tel://${phoneNumber}`}>
              {phoneNumber}
            </Typo>
            <Typo
              color="textPrimary"
              as="a"
              href={`mailto:${emailAddress}?subject=${t('footer.mail-to')}`}
              target="_blank"
            >
              {emailAddress}
            </Typo>
          </Typo>
          <nav className={clsx(classes.contentCol, classes.topContainerLinks)}>
            <Link
              href={`/${contactFormLink.slug}`}
              aria-label={t('footer.contact-form')!}
            >
              <Button
                className={classes.link}
                disableHover
                variant="text"
                inline
              >
                {t('footer.contact-form')}
              </Button>
            </Link>
            {links.map((link) => {
              return (
                <Link
                  key={link.text}
                  href={link.url}
                  target={link.external ? '_blank' : '_self'}
                  aria-label={link.text}
                >
                  <Button
                    className={classes.link}
                    disableHover
                    variant="text"
                    endAdornment={link.external ? externalLinkIcon : undefined}
                    inline
                  >
                    {link.text}
                  </Button>
                </Link>
              );
            })}
          </nav>
          <Link
            href={globalSettings.donationSlug}
            aria-label={t('cta-donate')!}
          >
            <Button className={classes.ctaButton} color="primary">
              {t('cta-donate')}
            </Button>
          </Link>
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.alignLeft}>
            <Typo variant="paragraph2" className={classes.copyRightElement}>
              &copy; {new Date().getFullYear()} PUSCH
            </Typo>
            {legalLinks.map((link) => {
              return (
                <Link
                  key={link.slug}
                  href={`/${link.slug}`}
                  aria-label={link.title}
                >
                  <Button
                    typoVariant="paragraph2"
                    inline
                    disableHover
                    variant="text"
                    className={classes.secondaryContentLink}
                  >
                    {link.title}
                  </Button>
                </Link>
              );
            })}
          </div>

          <div className={clsx(classes.alignRight)}>
            {socialMediaLinks.map((link, index) => {
              return (
                <Link
                  key={index}
                  href={link.url}
                  target="_blank"
                  aria-label={link.typ}
                >
                  <IconButton
                    className={classes.socialMediaLink}
                    variant="plain"
                    size="small"
                  >
                    {socialMediaIcons[link.typ]}
                  </IconButton>
                </Link>
              );
            })}
          </div>
        </div>
      </Container>
    </footer>
  );
};
