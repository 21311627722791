import {
  ArrowRightIcon,
  Button,
  Chip,
  HighlightBox,
  Typo,
} from '@dreipol/pusch-components';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { RenderInlineRecordContext } from 'react-datocms';
import { RenderBlockContext } from 'react-datocms/src/StructuredText';
import { ContactForm } from 'src/components/contact-form/contact-form';
import { DonationForm } from 'src/components/donation-form/donation-form';
import { StructuredTextAccordion } from 'src/components/structured-text-accordion/structured-text-accordion';
import { StructuredTextGallery } from 'src/components/structured-text-gallery/structured-text-gallery';
import { StructuredTextSecodaryText } from 'src/components/structured-text-secondary-text/structured-text-secondary-text';
import { StructuredTextSocialMediaEmbed } from 'src/components/structured-text-social-media-embed/structured-text-social-media-embed';
import { StrucutredTextVideo } from 'src/components/structured-text-video/structured-text-video';
import {
  DatoAccordionPlugin,
  DatoAudioPlugin,
  DatoContactFormPlugin,
  DatoFocusArticlePlugin,
  DatoGalleryPlugin,
  DatoGeneralTeaserPlugin,
  DatoGlossaryListPlugin,
  DatoImageGalleryPlugin,
  DatoImagePlugin,
  DatoPlugins,
  DatoQuotePlugin,
  DatoSecondaryTextPlugin,
  DatoSocialMediaEmbedPlugin,
  DatoVideoPlugin,
} from '../../types/dato-plugins';
import { ArticleFilterList } from '../components/article-filter-list/article-filter-list';
import { FocusArticle } from '../components/focus-article/focus-article';
import { GlossaryAccordion } from '../components/glossary-accordion/glossary-accordion';
import { KeywordTooltip } from '../components/keyword-tooltip/keyword-tooltip';
import { MapCta } from '../components/map-cta/map-cta';
import { RecursiveContent } from '../components/recursive-content/recursive-content';
import { StructuredTextImage } from '../components/structured-text-image/structured-text-image';
import { StructuredTextQuote } from '../components/structured-text-quote/structured-text-quote';
import { Teaser } from '../components/teaser/teaser';
import { useGlobalSettings } from '../context/global-settings-context';
import { getArticleRoute, getAuthorDetailRoute } from '../routes';
import { getArticleFilters, getRubric } from './get-filter-by-type';
import classes from './render-structured-content.module.scss';
import { useRouter } from 'next/router';
import { FALLBACK_LOCALE } from 'config';
import { useArticleFilterContext } from 'src/context/article-filter-context';
import { useRef } from 'react';
import { PRACTICE_MAP_SLUG } from 'config';
import { StructuredTextImageGallery } from 'src/components/structured-text-image-gallery copy/structured-text-image-gallery';

export const renderInlineRecord =
  (className?: string) => (ctx: RenderInlineRecordContext<DatoPlugins>) => {
    const router = useRouter();
    const globalSettings = useGlobalSettings();

    if (ctx.record._modelApiKey === 'keyword') {
      return (
        <KeywordTooltip
          className={className}
          description={ctx.record.glossary?.description ?? ''}
          slug={globalSettings.glossarySlug}
          recordId={ctx.record.glossary?.id}
          label={ctx.record.term}
        />
      );
    }

    if (ctx.record._modelApiKey === 'article') {
      return (
        <InlineRecordLink
          href={getArticleRoute(
            ctx.record.slug,
            router.locale ?? FALLBACK_LOCALE,
          )}
          label={ctx.record.title}
        />
      );
    }
    if (ctx.record._modelApiKey === 'author') {
      return (
        <InlineRecordLink
          href={getAuthorDetailRoute(
            ctx.record.slug,
            router.locale ?? FALLBACK_LOCALE,
          )}
          label={ctx.record.name}
        />
      );
    }
    if (ctx.record._modelApiKey === 'page') {
      return (
        <InlineRecordLink
          href={`/${ctx.record.slug}`}
          label={ctx.record.title}
        />
      );
    }

    console.warn(
      `No render rule found for inline record ${ctx.record._modelApiKey}`,
      ctx,
    );

    return null;
  };

export const genericRenderBlock =
  (
    blockClasses: Partial<Record<DatoPlugins['_modelApiKey'], string>> = {},
    className?: string,
  ) =>
  (ctx: RenderBlockContext<DatoPlugins>) => {
    if (ctx.record._modelApiKey === 'artikel_liste') {
      return (
        <ArticleFilterList
          className={clsx(className, blockClasses[ctx.record._modelApiKey])}
          teaser={ctx.record.teaser}
        />
      );
    }

    if (ctx.record._modelApiKey === 'article_highlight_plugin') {
      return renderFocusArticle(ctx.record);
    }

    if (ctx.record._modelApiKey === 'general_teaser') {
      return renderGeneralTeaser(ctx.record);
    }

    if (ctx.record._modelApiKey === 'glossary_list') {
      return renderGlossaryList(ctx.record);
    }

    if (ctx.record._modelApiKey === 'image_plugin') {
      return renderImage(ctx.record);
    }

    if (ctx.record._modelApiKey === 'video_plugin') {
      return renderVideo(ctx.record);
    }

    if (ctx.record._modelApiKey === 'gallery_plugin') {
      return renderGallery(ctx.record);
    }

    if (ctx.record._modelApiKey === 'image_gallery_plugin') {
      return renderImageGallery(ctx.record);
    }

    if (ctx.record._modelApiKey === 'accordion_plugin') {
      return renderAccordion(ctx.record);
    }

    if (ctx.record._modelApiKey === 'quote') {
      return renderQuote(ctx.record);
    }

    if (ctx.record._modelApiKey === 'audio_plugin') {
      return renderAudioPlayer(ctx.record);
    }

    if (ctx.record._modelApiKey === 'secondary_text') {
      return renderSecondaryText(ctx.record);
    }

    if (ctx.record._modelApiKey === 'map_view_cta') {
      return renderMapCta();
    }

    if (ctx.record._modelApiKey === 'contact_form') {
      return renderContactForm(ctx.record);
    }

    if (ctx.record._modelApiKey === 'donation_form') {
      return renderDonationForm();
    }

    if (ctx.record._modelApiKey === 'button') {
      return (
        <Link href={ctx.record.url}>
          <Button
            className={clsx(className, blockClasses[ctx.record._modelApiKey])}
            endAdornment={<ArrowRightIcon />}
            typoVariant={'inherit'}
            variant={'contained'}
            color={'primary'}
          >
            {ctx.record.label}
          </Button>
        </Link>
      );
    }

    if (ctx.record._modelApiKey === 'social_media_embed_plugin') {
      return renderSocialMediaEmbed(ctx.record);
    }

    if (ctx.record._modelApiKey === 'interview_headline') {
      return (
        <Typo variant="paragraph1" bold className={classes.interviewHeadline}>
          {ctx.record.text}
        </Typo>
      );
    }

    if (ctx.record._modelApiKey === 'highlight_box_plugin') {
      return (
        <HighlightBox
          className={classes.highlightBoxRoot}
          header={
            <Typo variant="h6" color="primary">
              {ctx.record.titel}
            </Typo>
          }
        >
          <div className={classes.content}>
            <RecursiveContent
              paragraphTypoVariant="paragraph2"
              data={ctx.record.content}
            ></RecursiveContent>
          </div>
        </HighlightBox>
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.warn(`No block found for ${ctx.record._modelApiKey}`, ctx);

    return null;
  };

const InlineRecordLink = ({ href, label }: { href: string; label: string }) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { setScrollingPos } = useArticleFilterContext()!;

  const handleClick = () => {
    //save scroll pos to session storage in order to preserve it on redirect
    if (
      href.includes(PRACTICE_MAP_SLUG.de) ||
      href.includes(PRACTICE_MAP_SLUG.fr)
    )
      setScrollingPos(linkRef.current?.offsetTop ?? 0);
  };

  return (
    <Link
      ref={linkRef}
      onClick={handleClick}
      className={classes.link}
      href={href}
      target="_self"
    >
      <Typo
        color="primary"
        underline
        variant="inherit"
        as="span"
        className={classes.textLink}
      >
        {label}
      </Typo>
    </Link>
  );
};

const renderDonationForm = () => {
  return <DonationForm></DonationForm>;
};

const renderContactForm = (block: DatoContactFormPlugin) => {
  return (
    <ContactForm
      image={block.image}
      name={block.name}
      occupation={block.occupation}
      text={block.text}
      receiverEmail={block.receiverEmail}
      successMessage={block.successMessage}
    ></ContactForm>
  );
};

const renderSocialMediaEmbed = (block: DatoSocialMediaEmbedPlugin) => {
  return (
    <StructuredTextSocialMediaEmbed
      title={block.title}
      description={block.description}
      thumbnail={block.thumbnail}
      plattform={block.plattform}
      blogpostLink={block.blogpostLink}
      height={block.height}
    ></StructuredTextSocialMediaEmbed>
  );
};

const renderQuote = (block: DatoQuotePlugin) => {
  return (
    <StructuredTextQuote
      image={block.image}
      quote={block.quote}
      author={block.author}
      quotationMarks={block.quotationMarks}
    ></StructuredTextQuote>
  );
};

const renderAudioPlayer = (block: DatoAudioPlugin) => {
  const DynamicStructuredTextAudio = dynamic(
    () =>
      import('../components/structured-text-audio/structured-text-audio').then(
        (mod) => mod.StructuredTextAudio,
      ),
    { ssr: false },
  );
  return (
    <DynamicStructuredTextAudio
      file={block.file}
      title={block.title}
      additionalText={block.additionalText}
    ></DynamicStructuredTextAudio>
  );
};

const renderSecondaryText = (block: DatoSecondaryTextPlugin) => {
  return (
    <StructuredTextSecodaryText text={block.text}></StructuredTextSecodaryText>
  );
};

const renderMapCta = () => {
  return <MapCta />;
};

const renderAccordion = (block: DatoAccordionPlugin) => {
  return (
    <StructuredTextAccordion
      title={block.title}
      content={block.content}
    ></StructuredTextAccordion>
  );
};

const renderGallery = (block: DatoGalleryPlugin) => {
  return (
    <StructuredTextGallery
      images={block.images}
      showLegend={block.showLegend}
    />
  );
};

const renderImageGallery = (block: DatoImageGalleryPlugin) => {
  return (
    <StructuredTextImageGallery
      images={block.images}
    ></StructuredTextImageGallery>
  );
};

const renderImage = (block: DatoImagePlugin) => {
  return (
    <StructuredTextImage
      image={block.image}
      showLegend={block.showLegend}
      aspectRatio={block.aspectRatio}
    ></StructuredTextImage>
  );
};

const renderVideo = (block: DatoVideoPlugin) => {
  return (
    <StrucutredTextVideo
      type="text"
      video={block.video}
      description={block.description}
      additionalFormats={block.additionalFormats}
      plattform={block.plattform}
      sourceLink={block.sourceLink}
      sourceName={block.sourceName}
    />
  );
};

const renderGlossaryList = (block: DatoGlossaryListPlugin) => {
  const router = useRouter();

  return <GlossaryAccordion title={block.title} locale={router.locale} />;
};

const renderGeneralTeaser = (block: DatoGeneralTeaserPlugin) => {
  return (
    <Teaser
      title={block.title}
      href={block.ctaUrl}
      external={block.externalLink}
      cta={
        <Button
          color={'secondary'}
          variant={'outlinedAlternate'}
          endAdornment={<ArrowRightIcon />}
        >
          {block.ctaLabel}
        </Button>
      }
    >
      <RecursiveContent data={block.text} />
    </Teaser>
  );
};

const renderFocusArticle = (block: DatoFocusArticlePlugin) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <FocusArticle
      title={block.title === '' ? block.article.title : block.title}
      href={getArticleRoute(
        block.article.slug,
        router.locale ?? FALLBACK_LOCALE,
      )}
      color={getRubric(block.article.filters ?? [])?.color}
      content={block.text === '' ? block.article.lead : block.text}
      cta={
        <Button variant={'plain'} endAdornment={<ArrowRightIcon />}>
          {t(`general.reading_time_cta`, {
            readingTime: block.article?.readingTime,
          })}
        </Button>
      }
      tags={
        <>
          {getArticleFilters(block.article.filters).map(
            (filter) =>
              filter && (
                <Chip color="transparent" key={filter.id}>
                  {filter.term}
                </Chip>
              ),
          )}
        </>
      }
    />
  );
};
