'use client';

import { Tooltip, Typo } from '@dreipol/pusch-components';
import clsx from 'clsx';
import { StructuredText } from 'datocms-structured-text-utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useCallback, useRef, useState } from 'react';
import { DatoPlugins } from '../../../types/dato-plugins';
import { RecursiveContent } from '../recursive-content/recursive-content';
import classes from './_keyword-tooltip.module.scss';

export type KeywordTooltipProps = {
  description: StructuredText<DatoPlugins>;
  slug: string;
  recordId: string;
  label: string;
  className?: string;
};

export const KeywordTooltip = ({
  label,
  description,
  slug,
  recordId,
  className,
}: KeywordTooltipProps) => {
  const { t } = useTranslation();
  const [isClamped, setClamped] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const onOpen = useCallback(() => {
    if (!contentRef.current) {
      return;
    }
    setClamped(
      contentRef.current.scrollHeight > contentRef.current.clientHeight,
    );
  }, [contentRef]);

  return (
    <Tooltip
      portal={'#tooltipPortal'}
      onOpen={onOpen}
      content={
        <div>
          <div ref={contentRef} className={classes.root}>
            <RecursiveContent
              className={clsx(className, classes.content)}
              data={description}
              paragraphTypoVariant={'label1'}
            />
          </div>
          {isClamped && (
            <Link
              target={'_blank'}
              href={`${slug}/?id=${recordId}`}
              className={classes.link}
            >
              {t('general.go_to_glossary')}
            </Link>
          )}
        </div>
      }
    >
      <Typo as={'span'} variant={'inherit'}>
        {label}
      </Typo>
    </Tooltip>
  );
};
