'use client';

import {
  usePathname,
  useSearchParams as userRouterSearchParams,
} from 'next/navigation';
import { useRouter } from 'next/dist/client/router';
import { useCallback } from 'react';
import { useLocation } from 'react-use';

const setParamValue = (
  urlParams: URLSearchParams,
  key: string,
  value: string | string[],
) => {
  if (Array.isArray(value)) {
    if (urlParams.has(key)) {
      urlParams.delete(key);
    }
    value.forEach((v) => {
      if (urlParams.has(key)) {
        urlParams.append(key, v.toString());
      } else {
        urlParams.set(key, v.toString());
      }
    });
  } else {
    urlParams.set(key, value.toString());
  }
  return urlParams;
};

export const useSearchParams = (replace = false) => {
  const router = useRouter();
  const pathName = usePathname();
  const routerParams = userRouterSearchParams();
  const location = useLocation();
  const rootUrl = `${router.pathname}`;

  const setParam = useCallback(
    (key: string, value: string | string[]) => {
      if (!pathName) {
        return;
      }
      const newUrlParams = setParamValue(new URLSearchParams(), key, value);
      if (replace) {
        router.replace(`${rootUrl}?${newUrlParams.toString()}`, undefined, {
          scroll: false,
          shallow: true,
        });
      } else {
        router.push(`${rootUrl}?${newUrlParams.toString()}`, undefined, {
          scroll: false,
          shallow: true,
        });
      }
    },
    [router, pathName, location],
  );

  const set = useCallback(
    (params: Record<string, string | string[]>) => {
      if (!pathName) {
        return;
      }

      if (replace) {
        router.replace({
            pathname: router.pathname,
            query: {
              ...router.query,
              ...params,
            }
          }, undefined, {
          scroll: false,
          shallow: true,
        });
      } else {
        router.push({
            pathname: router.pathname,
            query: {
              ...router.query,
              ...params,
            }
          }, undefined, {
          scroll: false,
          shallow: true,
        });
      }
    },
    [router, pathName, location],
  );

  const remove = useCallback(
    (params: string[]) => {
      if (!pathName) {
        return;
      }

      const newUrlParams = params.reduce((urlParams, key) => {
        urlParams.delete(key);
        return urlParams;
      }, new URLSearchParams(routerParams));

      if (replace) {
        router.replace(`${rootUrl}?${newUrlParams.toString()}`, undefined, {
          scroll: false,
          shallow: true,
        });
      } else {
        router.push(`${rootUrl}?${newUrlParams.toString()}`, undefined, {
          scroll: false,
          shallow: true,
        });
      }
    },
    [router, pathName, location],
  );

  return {
    set,
    setParam,
    remove,
    get: routerParams.get,
    getAll: routerParams.getAll,
    routerParams,
  };
};
