import { ArrowRightIcon, Button, Chip } from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { DatoArticle } from '../../../types/dato';
import { DatoFocusArticlePlugin } from '../../../types/dato-plugins';
import { getArticleRoute } from '../../routes';
import { getArticleFilters, getRubric } from '../../utils/get-filter-by-type';
import { ArticleCard } from '../article-card/article-card';
import { FocusArticle } from '../focus-article/focus-article';
import { Stack } from '../stack/stack';
import { useRouter } from 'next/router';
import { FALLBACK_LOCALE } from 'config';

export type ArticleListProps = {
  articles: DatoArticle[];
  teaser?: DatoFocusArticlePlugin[];
};
export const ArticleList = ({ articles, teaser }: ArticleListProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const isXs = useMediaQuery('xs');
  const list = useMemo(() => {
    if (!teaser) {
      return [...articles];
    }
    return teaser?.reduce(
      (items, teaserItem) => {
        if (
          !!teaserItem.listingPosition &&
          teaserItem.listingPosition < items.length
        ) {
          items.splice(teaserItem.listingPosition, 0, teaserItem);
        }
        return items;
      },
      [...articles] as (DatoArticle | DatoFocusArticlePlugin)[],
    );
  }, [articles, teaser]);

  return (
    <Stack space={isXs ? 5.5 : 5}>
      {list?.map((a, index) => {
        if (
          (a as DatoFocusArticlePlugin)._modelApiKey ===
          'article_highlight_plugin'
        ) {
          const teaserItem = a as DatoFocusArticlePlugin;

          return (
            <FocusArticle
              key={teaserItem.id}
              title={
                teaserItem.title === ''
                  ? teaserItem.article.title
                  : teaserItem.title
              }
              color={getRubric(teaserItem.article.filters ?? [])?.color}
              href={getArticleRoute(
                teaserItem.article.slug,
                router.locale ?? FALLBACK_LOCALE,
              )}
              content={
                teaserItem.text === ''
                  ? teaserItem.article.lead
                  : teaserItem.text
              }
              tags={
                <>
                  {getArticleFilters(teaserItem.article.filters).map(
                    (filter) =>
                      filter && (
                        <Chip key={filter.id} color="transparent">
                          {filter.term}
                        </Chip>
                      ),
                  )}
                </>
              }
              cta={
                <Button variant={'plain'} endAdornment={<ArrowRightIcon />}>
                  {t('general.reading_time_cta', {
                    readingTime: teaserItem.article.readingTime,
                  })}
                </Button>
              }
            ></FocusArticle>
          );
        } else {
          const article = a as DatoArticle;
          return (
            <ArticleCard
              articleSlug={article.slug}
              index={index}
              key={article.id}
              imageData={article.image}
              title={article.title}
              authors={article.authors}
              creationDate={
                article.publicationDateOverride ?? article._firstPublishedAt
              }
              teaser={article.lead}
              readingTime={article.readingTime}
              filters={article.filters}
            />
          );
        }
      })}
    </Stack>
  );
};
