import { useMemo } from 'react';

export const useIdList = <OBJ extends object, T extends string | number>(
  list: ({ id: T } & OBJ)[],
  deps: unknown[],
) => {
  return useMemo(
    () =>
      list.reduce((map, item) => {
        map.set(item.id, item);
        return map;
      }, new Map<T, OBJ>()),
    [...deps],
  );
};
