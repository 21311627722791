import { Button, Typo } from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import classes from './cookie-banner.module.scss';
import { useGlobalSettings } from 'src/context/global-settings-context';

export const CookieBanner = () => {
  const USER_CONSENT_COOKIE_KEY = 'cookie_consent';
  const USER_CONSENT_COOKIE_EXPIRE_DATE = 1;
  const [cookieConsent, setCookieConsent] = useState(false);
  const isSmallerLg = useMediaQuery(['md', 'xs', 'sm']);
  const { t } = useTranslation();
  const globalSettings = useGlobalSettings();

  useEffect(() => {
    const consentIsTrue = Cookies.get(USER_CONSENT_COOKIE_KEY) === 'true';
    setCookieConsent(consentIsTrue);
  }, []);

  const setConsent = () => {
    if (!cookieConsent) {
      Cookies.set(USER_CONSENT_COOKIE_KEY, 'true', {
        expires: USER_CONSENT_COOKIE_EXPIRE_DATE,
      });
      setCookieConsent(true);
    }
  };

  return !cookieConsent ? (
    <div className={classes.root}>
      <Typo className={classes.infoText} variant="label1">
        {t('cookie.text')}
        <Link href={globalSettings.dataProtectionSlug ?? ''}>
          <Button
            typoVariant="label1"
            className={classes.link}
            color="primary"
            inline
            variant="link"
          >
            {t('cookie.privacy-statement')}
          </Button>
        </Link>
        .
      </Typo>
      <Button
        fullWidth={isSmallerLg}
        onClick={() => setConsent()}
        color="primary"
      >
        {t('cookie.close-button')}
      </Button>
    </div>
  ) : null;
};
