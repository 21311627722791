import { ChevronDownIcon, IconButton, Typo } from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import clsx from 'clsx';
import Image from 'next/image';
import { useCallback, useState } from 'react';
import { DatoGalleryImage } from 'types/dato';
import { MediaSource } from '../media-source/media-source';
import classes from './structured-text-image-gallery.module.scss';

export type StructuredTextImageGalleryProps = {
  /**
   * images of gallery
   */
  images: DatoGalleryImage[];
};

export const StructuredTextImageGallery = ({
  images,
}: StructuredTextImageGalleryProps) => {
  //currently selected image
  const [currentSelected, setCurrentSelected] = useState(0);
  //navigation direction indicater (animation trigger)
  const [navigationDirection, setNavigationDirection] = useState('');
  const isXs = useMediaQuery('xs');

  /**
   * Handle back button click
   */
  const handleBackNavigation = useCallback((): void => {
    setNavigationDirection('back');
    setCurrentSelected((prev) => prev - 1);
  }, [navigationDirection]);

  /**
   * Handle forward button click
   */
  const handleForwardNavigation = useCallback((): void => {
    setNavigationDirection('forward');
    setCurrentSelected((prev) => prev + 1);
  }, [navigationDirection]);

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <IconButton
          onClick={handleBackNavigation}
          variant={isXs ? 'contained' : 'plain'}
          size="small"
          className={clsx(classes.backButton, {
            [classes.hidden]: currentSelected === 0,
          })}
        >
          <ChevronDownIcon />
        </IconButton>
        <Image
          key={currentSelected}
          className={clsx(classes.selectedImage, classes[navigationDirection])}
          src={images[currentSelected].image.url}
          alt={
            images[currentSelected].image.alt ??
            images[currentSelected].image.title ??
            ''
          }
          width="0"
          height="0"
          sizes="100vw"
        ></Image>
        <IconButton
          onClick={handleForwardNavigation}
          variant={isXs ? 'contained' : 'plain'}
          size="small"
          className={clsx(classes.nextButton, {
            [classes.hidden]: currentSelected === images.length - 1,
          })}
        >
          <ChevronDownIcon />
        </IconButton>
        <div className={classes.counter}>
          {`${currentSelected + 1} / ${images.length}`}
        </div>
      </div>
      {images[currentSelected].showLegend && (
        <Typo className={classes.caption} variant="paragraph2">
          {images[currentSelected].image.title}
          <MediaSource
            sourceLink={images[currentSelected]?.image.customData?.sourceLink}
            sourceName={images[currentSelected]?.image.customData?.sourceName}
            mediaType="image"
          ></MediaSource>
        </Typo>
      )}
    </div>
  );
};
