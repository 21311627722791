import clsx from 'clsx';
import { CSSProperties, forwardRef, PropsWithChildren, useMemo } from 'react';
import classes from './stack.module.scss';

export type StackProps = PropsWithChildren<{
  /**
   * Spacing factor
   */
  space?: number;

  /**
   * Direction of the stack
   */
  direction?: 'column' | 'row';

  /**
   * Custom class name
   */
  className?: string;
}>;

/* eslint-disable react/display-name */
export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ children, space, className, direction = 'column' }, ref) => {
    const style = useMemo(
      () => ({ '--stack--space': space } as CSSProperties),
      [space],
    );

    return (
      <div
        className={clsx(classes.root, classes[direction], className)}
        style={style}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
