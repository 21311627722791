import { createContext, useContext } from 'react';

export type GlobalSettingsContextType = {
  glossarySlug: string;
  donationSlug: string;
  dataProtectionSlug: string;
};

const GlobalSettingsContextValue: GlobalSettingsContextType = {
  glossarySlug: '/',
  donationSlug: "/",
  dataProtectionSlug: '/'
};
export const GlobalSettingsContext = createContext<GlobalSettingsContextType>(
  GlobalSettingsContextValue,
);
export const useGlobalSettings = () => useContext(GlobalSettingsContext);
