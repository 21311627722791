'use client';

import classes from './map-legend.module.scss';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import {
  InfoIcon,
  CloseIcon,
  IconButton,
  Typo,
} from '@dreipol/pusch-components';
import React, { useState } from 'react';
import Image from 'next/image';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { Overlay } from '../overlay/overlay';

export type MapLegendProps = {
  className?: string;
  showButton?: boolean;
};

export const MapLegend = ({ className, showButton }: MapLegendProps) => {
  const { t } = useTranslation();
  const [showLegend, setShowLegend] = useState(false);
  const [showLegendOverlay, setShowLegendOverlay] = useState(false);
  const isTablet = useMediaQuery(['xs', 'sm', 'md']);

  const legend = (
    <div
      className={clsx(classes.legend, showLegend ? classes.isVisible : null)}
    >
      <div className={classes.legendItem}>
        <div className={classes.legendIconWrapper}>
          <Image
            alt={'School image'}
            width={48}
            height={60}
            src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/schule_legend.svg`}
          />
        </div>
        <Typo variant={'label2'} color={'textSecondary'}>
          {t('map.legend.school_label')}
        </Typo>
      </div>

      <div className={classes.legendItem}>
        <div className={classes.legendIconWrapper}>
          <Image
            alt={'Gemeinde image'}
            width={48}
            height={60}
            src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/gemeinde_legend.svg`}
          />
        </div>
        <Typo variant={'label2'} color={'textSecondary'}>
          {t('map.legend.community_label')}
        </Typo>
      </div>

      <div className={classes.legendItem}>
        <div className={classes.legendIconWrapper}>
          <Image
            alt={'Unternehmen image'}
            width={48}
            height={60}
            src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/unternehmen_legend.svg`}
          />
        </div>
        <Typo variant={'label2'} color={'textSecondary'}>
          {t('map.legend.corp_label')}
        </Typo>
      </div>

      <div className={classes.legendItem}>
        <div className={classes.legendIconWrapper}>
          <Image
            alt={'Zusammenarbeit image'}
            width={56}
            height={65}
            src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/collab_legend.svg`}
          />
        </div>
        <Typo variant={'label2'} color={'textSecondary'}>
          {t('map.legend.collab_label')}
        </Typo>
      </div>
    </div>
  );

  const legendOverlay = (
    <Overlay
      open={isTablet && showLegendOverlay}
      variant={'centered'}
      className={classes.legendOverlay}
    >
      <div className={classes.legendOverlayHeader}>
        <Typo variant={'h4'} color={'textPrimary'}>
          {t('map.legend.button_label')}
        </Typo>
        <IconButton
          className={classes.legendOverlayClose}
          color={'secondary'}
          variant={'text'}
          onClick={() => updateLegendOverlay(false)}
        >
          <CloseIcon size="large" />
        </IconButton>
      </div>
      <div className={classes.legendOverlayContent}>
        <div className={classes.legendOverlayItem}>
          <div className={classes.legendIconWrapper}>
            <Image
              alt={'Schule image'}
              width={48}
              height={60}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/schule_legend.svg`}
            />
          </div>
          <Typo variant={'label2'} color={'textSecondary'}>
            {t('map.legend.school_label')}
          </Typo>
        </div>
        <div className={classes.legendOverlayItem}>
          <div className={classes.legendIconWrapper}>
            <Image
              alt={'Gemeine image'}
              width={48}
              height={60}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/gemeinde_legend.svg`}
            />
          </div>
          <Typo variant={'label2'} color={'textSecondary'}>
            {t('map.legend.community_label')}
          </Typo>
        </div>
        <div className={classes.legendOverlayItem}>
          <div className={classes.legendIconWrapper}>
            <Image
              alt={'Unternehmen image'}
              width={48}
              height={60}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/unternehmen_legend.svg`}
            />
          </div>
          <Typo variant={'label2'} color={'textSecondary'}>
            {t('map.legend.corp_label')}
          </Typo>
        </div>
        <div className={classes.legendOverlayItem}>
          <div className={classes.legendIconWrapper}>
            <Image
              alt={'Zusammenarbeit image'}
              width={56}
              height={65}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/icons/collab_legend.svg`}
            />
          </div>
          <Typo variant={'label2'} color={'textSecondary'}>
            {t('map.legend.collab_label')}
          </Typo>
        </div>
      </div>
    </Overlay>
  );

  const updateShowLegend = (value: boolean) => {
    if (!isTablet) {
      setShowLegend(value);
    }
  };

  const updateLegendOverlay = (value: boolean) => {
    if (isTablet) {
      setShowLegendOverlay(value);
    }
  };

  return (
    <div className={clsx(className, classes.root)}>
      {legend}
      {legendOverlay}
      {showButton && (
        <button
          onClick={() => updateLegendOverlay(true)}
          onMouseEnter={() => updateShowLegend(true)}
          onMouseLeave={() => updateShowLegend(false)}
          className={classes.button}
        >
          <Typo className={classes.buttonText} variant={'button'}>
            {t('map.legend.button_label')}
          </Typo>
          <InfoIcon />
        </button>
      )}
    </div>
  );
};
