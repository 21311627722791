import clsx from 'clsx';
import { createElement, PropsWithChildren } from 'react';
import classes from './container.module.scss';

export type ContainerProps = PropsWithChildren<{
  /**
   * Custom HTML component
   */
  as?: string;

  /**
   * Custom className
   */
  className?: string;

  fullWidth?: boolean;
}>;

/**
 * Container component to keep the width of the content under control
 */
export const Container = ({
  children,
  as,
  className,
  fullWidth,
}: ContainerProps) => {
  return createElement(
    as ?? 'div',
    {
      className: clsx(className, classes.root, {
        [classes.fullWidth]: fullWidth,
      }),
    },
    children,
  );
};
