import { Typo } from '@dreipol/pusch-components';
import { DatoImage, DatoResponsiveImage } from 'types/dato';
import Image from 'next/image';
import {
  FacebookEmbed,
  TwitterEmbed,
  TikTokEmbed,
  InstagramEmbed,
  PinterestEmbed,
  LinkedInEmbed,
} from 'react-social-media-embed';
import classes from './structured-text-social-media-embed.module.scss';
import { createElement, useMemo } from 'react';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { NextURL } from 'next/dist/server/web/next-url';
import clsx from 'clsx';

export type StructuredTextSocialMediaEmbedProps = {
  /**
   * Title of custom embed
   */
  title: string;
  /**
   * Description of custom embed
   */
  description: string;
  /**
   * Thumbnail of custom embed
   */
  thumbnail: DatoResponsiveImage;
  /**
   * Platform of origin
   */
  plattform: string;
  /**
   * Url of blogpost
   */
  blogpostLink: string;
  /**
   * Custom height
   */
  height?: number;
};

export const StructuredTextSocialMediaEmbed = ({
  title,
  description,
  thumbnail,
  plattform,
  blogpostLink,
  height,
}: StructuredTextSocialMediaEmbedProps) => {
  const components = {
    Facebook: FacebookEmbed,
    Twitter: TwitterEmbed,
    Instagram: InstagramEmbed,
    TikTok: TikTokEmbed,
    Pinterest: PinterestEmbed,
    LinkedIn: LinkedInEmbed,
  };
  const isXs = useMediaQuery('xs');
  const host = useMemo(
    () => new NextURL(blogpostLink).host.replace('www.', ''),
    [blogpostLink],
  );
  const defaultHeight = isXs ? '414px' : '525px';

  return (
    <div className={classes.root}>
      {plattform === 'other' ? (
        <a
          href={blogpostLink}
          target="_blank"
          rel="noreferrer"
          className={classes.customEmbed}
        >
          <Image
            src={thumbnail.otherFormat ?? ''}
            alt={thumbnail.alt ?? thumbnail.title ?? ''}
            width="0"
            height="0"
            sizes="100vw"
            className={classes.image}
          ></Image>
          <div className={classes.textContainer}>
            <Typo variant="paragraph2" bold color="textSecondary">
              {host}
            </Typo>
            <Typo className={classes.title} variant="paragraph2" bold>
              {title}
            </Typo>
            <Typo
              className={classes.description}
              variant="paragraph2"
              color="textSecondary"
            >
              {description}
            </Typo>
          </div>
        </a>
      ) : (
        <div
          className={clsx({
            [classes.linkedInContainer]: plattform === 'LinkedIn',
          })}
        >
          {plattform === 'LinkedIn' && (
            <Image
              className={classes.linkedInLogo}
              width={25}
              height={25}
              src={`${process.env.NEXT_PUBLIC_APP_PATH}/assets/imgs/article/linkedin_logo.svg`}
              alt="Logo"
            />
          )}
          {createElement(components[plattform as keyof typeof components], {
            url: blogpostLink,
            width: isXs ? '100%' : '400px',
            height: height ? `${height}px` : defaultHeight,
          })}
        </div>
      )}
    </div>
  );
};
