import { GeoLocation } from '../components/map/map';

export const isWithinBounds = (
  item: GeoLocation,
  bounds: [GeoLocation, GeoLocation],
) => {
  return (
    item.lat >= bounds[0].lat &&
    item.lat <= bounds[1].lat &&
    item.lng >= bounds[0].lng &&
    item.lng <= bounds[1].lng
  );
};
