import { Button, Typo, TypoProps } from '@dreipol/pusch-components';
import clsx from 'clsx';
import {
  isHeading,
  isItemLink,
  isLink,
  isList,
  isParagraph,
  isThematicBreak,
  StructuredText as StructuredTextType,
} from 'datocms-structured-text-utils';
import Link from 'next/link';
import { createElement } from 'react';
import { renderNodeRule, StructuredText } from 'react-datocms';
import { DatoPlugins } from '../../../types/dato-plugins';
import {
  genericRenderBlock,
  renderInlineRecord,
} from '../../utils/render-structured-content';
import { Divider } from '../divider/divider';
import classes from './recursive-content.module.scss';

export type RecursiveContentProps = {
  data: StructuredTextType<DatoPlugins>;
  paragraphTypoVariant?: TypoProps['variant'];
  typoClassName?: string;
  className?: string;
  blockClasses?: Partial<Record<DatoPlugins['_modelApiKey'], string>>;
  typoProps?: Partial<Omit<TypoProps, 'ref'>>;
};

export function RecursiveContent({
  data,
  paragraphTypoVariant = 'paragraph1',
  typoClassName,
  className,
  blockClasses,
  typoProps,
}: RecursiveContentProps) {
  return (
    <StructuredText
      data={data}
      renderBlock={genericRenderBlock(blockClasses, className)}
      renderInlineRecord={renderInlineRecord(className)}
      renderLinkToRecord={renderInlineRecord(className)}
      customNodeRules={[
        renderNodeRule(isHeading, ({ node, children, key }) => {
          return (
            <Typo
              key={key}
              {...typoProps}
              as={`h${node.level}`}
              variant={`h${node.level}`}
              className={clsx(
                typoClassName,
                classes.heading,
                classes[`h${node.level}Margin`],
                classes[`h${node.level}`],
              )}
            >
              {children}
            </Typo>
          );
        }),
        renderNodeRule(isParagraph, ({ node, children, key }) => {
          return (
            <Typo
              {...typoProps}
              className={clsx(typoClassName, classes.pMargin, className)}
              key={key}
              variant={paragraphTypoVariant}
            >
              {children}
            </Typo>
          );
        }),
        renderNodeRule(isLink, ({ node, children, key }) => {
          const targetMetaInfo = node.meta?.find(
            (item) => item.id === 'target',
          );

          return (
            <Link key={key} href={node.url} target={targetMetaInfo?.value}>
              <Typo
                color="primary"
                underline
                variant="inherit"
                as="span"
                className={clsx(className, classes.textLink)}
              >
                {children}
              </Typo>
            </Link>
          );
        }),
        renderNodeRule(isThematicBreak, ({ node, children, key }) => {
          return <Divider />;
        }),
        renderNodeRule(isList, ({ node, children, key }) => {
          return createElement(
            node.style === 'bulleted' ? 'ul' : 'ol',
            { className: classes.list, key: key },
            children,
          );
        }),
      ]}
    />
  );
}
