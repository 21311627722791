import {
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
  Typo,
} from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import clsx from 'clsx';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation, Swiper as SwiperT } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DatoArticleWithLocation } from '../../../types/dato';
import { useFormattedDate } from '../../hooks/use-formatted-date';
import { ReducedArticleListItem } from '../reduced-article-list-item/reduced-article-list-item';
import classes from './map-article-list.module.scss';

export type MapArticleListProps = PropsWithChildren<{
  articles: DatoArticleWithLocation[];
  onClick?: (article: DatoArticleWithLocation) => void;
  onHover?: (id: string | null) => void;
  hoveringId?: string | null;
  className?: string;
}>;

export const MapArticleList = ({
  articles,
  onClick,
  onHover,
  hoveringId,
  children,
  className,
}: MapArticleListProps) => {
  const dateFormatter = useFormattedDate();
  const showSwiper = useMediaQuery(['xs', 'sm', 'md', 'lg']);
  const [swiperInstance, setSwiper] = useState<SwiperT | null>(null);
  const [canSlideNext, setSlideNext] = useState(true);
  const [canSlidePrev, setSlidePrev] = useState(true);
  const { t } = useTranslation();

  const onSwiperUpdate = useCallback(
    (swiper: SwiperT) => {
      onHover?.(articles[swiper.activeIndex]?.id);
      setSlideNext(swiper.activeIndex < swiper.slides.length - 1);
      setSlidePrev(swiper.activeIndex > 0);
    },
    [onHover, articles],
  );

  useEffect(() => {
    if (!swiperInstance || swiperInstance.destroyed) {
      return;
    }
    setSlideNext(swiperInstance.activeIndex < swiperInstance.slides.length - 1);
    setSlidePrev(swiperInstance.activeIndex > 0);
  }, [swiperInstance]);

  if (showSwiper) {
    return (
      <div className={clsx(classes.root, className)}>
        <Swiper
          modules={[Navigation]}
          slidesPerView={1}
          spaceBetween={50}
          onSwiper={setSwiper}
          onActiveIndexChange={onSwiperUpdate}
          onUpdate={onSwiperUpdate}
          className={classes.swiperList}
        >
          {articles.length > 0 ? (
            articles.map((article) => (
              <SwiperSlide
                key={article.id}
                className={clsx(classes.listItem, {})}
              >
                <ReducedArticleListItem
                  onMouseOver={() => onHover?.(article.id)}
                  onMouseOut={() => onHover?.(null)}
                  src={article.image.oneToOne ?? ''}
                  slug={article.slug}
                  title={article.title}
                  alt={article.image.alt}
                  date={dateFormatter(
                    article.publicationDateOverride ??
                      article._firstPublishedAt,
                  )}
                  color={article.color}
                  lead={article.lead}
                  filter={article.filters.find(
                    (itm) => itm.filtertype === 'rubric',
                  )}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide className={clsx(classes.listItem)}>
              <Typo className={classes.noArticleWarning} variant="h6">
                {t('map.article-list.no_article')}
              </Typo>
            </SwiperSlide>
          )}
          <div className={classes.navigationButtonWrapper}>
            <IconButton
              disabled={!canSlidePrev}
              variant={'plain'}
              className={clsx(classes.navigationButton, {
                [classes.navigationButtonDisabled]: !canSlidePrev,
              })}
              color={'secondary'}
              onClick={() => swiperInstance?.slidePrev()}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={!canSlideNext}
              variant={'plain'}
              className={clsx(
                classes.navigationButton,
                classes.navigationButtonRight,
                { [classes.navigationButtonDisabled]: !canSlideNext },
              )}
              color={'secondary'}
              onClick={() => swiperInstance?.slideNext()}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </Swiper>
        {children}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {articles.length > 0 ? (
          articles.map((article) => (
            <li
              className={classes.listItem}
              key={article.slug}
              onClick={() => onClick?.(article)}
            >
              <ReducedArticleListItem
                onMouseOver={() => onHover?.(article.id)}
                onMouseOut={() => onHover?.(null)}
                src={article.image.oneToOne ?? ''}
                slug={article.slug}
                title={article.title}
                alt={article.image.alt}
                date={dateFormatter(
                  article.publicationDateOverride ?? article._firstPublishedAt,
                )}
                color={article.color}
                lead={article.lead}
                filter={article.filters.find(
                  (itm) => itm.filtertype === 'rubric',
                )}
              />
            </li>
          ))
        ) : (
          <Typo className={classes.noArticleWarning} variant="h6">
            {t('map.article-list.no_article')}
          </Typo>
        )}
      </ul>
      {children}
    </div>
  );
};
