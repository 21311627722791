import { Avatar, Typo } from '@dreipol/pusch-components';
import { useMediaQuery } from '@dreipol/t3-react-media';
import { DatoResponsiveImage } from 'types/dato';
import { Quote } from '../quote/quote';
import classes from './structured-text-quote.module.scss';

export type StructuredTextQuoteProps = {
  /**
   * Quote avatar image
   */
  image?: DatoResponsiveImage;

  /**
   * Quote text
   */
  quote: string;

  /**
   * Author of quote
   */
  author?: string;

  /**
   * Indicates whether quote has quotation marks
   */
  quotationMarks: boolean;
};

export const StructuredTextQuote = ({
  image,
  quote,
  author,
  quotationMarks,
}: StructuredTextQuoteProps) => {
  const isSmallerMd = useMediaQuery(['xs', 'sm', 'md']);

  return (
    <div className={classes.root}>
      {image && (
        <Avatar
          size={isSmallerMd ? 'xs' : 'md'}
          url={image.oneToOne ?? ''}
          alt={image.title ?? ''}
        ></Avatar>
      )}
      <Quote quotationMarks={quotationMarks} variant="h3" centered>
        {quote}
      </Quote>
      {author && <Typo variant="paragraph2">– {author}</Typo>}
    </div>
  );
};
