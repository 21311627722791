import { DatoFilter } from '../../types/dato';

export const getFilterByType = (type: string, filters: DatoFilter[]) => {
  return filters.find((filter) => filter.filtertype === type);
};

export const getFiltersByType = (type: string, filters: DatoFilter[]) => {
  return filters.filter((filter) => filter.filtertype === type);
};

export const getRubric = (filters: DatoFilter[]) =>
  getFilterByType('rubric', filters);

export const getPointOfView = (filters: DatoFilter[]) =>
  getFiltersByType('point-of-view', filters);

export const getFormat = (filters: DatoFilter[]) =>
  getFiltersByType('format', filters);

export const getArticleFilters = (filters: DatoFilter[]) =>
  Array.of(getFilterByType('rubric', filters)).concat(
    getFilterByType('format', filters),
  );
